import { Flex, Label } from '@pelotoncycle/design-system';
import React from 'react';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import useCartWithVariables from '../hooks/useCartWithVariables';
import CartContent from '../shared/CartContent';
import PriceWithCents from '../shared/PriceWithCents';
import ShippingTooltip from './ShippingTooltip';

const Shipping: React.FC<React.PropsWithChildren<unknown>> = () => {
  const cart = useCartWithVariables().data?.cart;
  const { hasRow, hasBike, hasBikePlus, hasTread, shipping } = cart ?? {};
  const hasOnlyRow = hasRow && !(hasBike || hasBikePlus || hasTread);
  const isShippingIncluded = shipping?.isIncluded;
  const shippingAmount = shipping?.price.amount ?? 0;
  const isToggleActive = useIsToggleActive();
  const isRowSelfAssemblyEnabled = isToggleActive('caesarSelfAssembly');
  const contentfulShippingLabel = () => {
    if (!shipping || shipping.isEstimated) {
      if (isRowSelfAssemblyEnabled && hasOnlyRow) {
        return 'estimatedShippingLabelDeliveryOnly';
      }
      return 'estimatedShippingLabel';
    }
    return 'shippingLabel';
  };

  return (
    <Flex justifyContent="space-between">
      <Flex>
        <Label size="large" as="span">
          <CartContent field={contentfulShippingLabel()} />
        </Label>
        {!isShippingIncluded && shippingAmount > 0 && <ShippingTooltip />}
      </Flex>
      <Label size="large" as="span" data-test-id="shippingCost">
        {isShippingIncluded ? (
          <CartContent field="shippingIncluded" />
        ) : shippingAmount > 0 ? (
          <PriceWithCents amount={shippingAmount} />
        ) : (
          <CartContent field="noAmount" />
        )}
      </Label>
    </Flex>
  );
};

export default Shipping;
