import { red, grey, Flex, Label, spacing } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import FormatText from '@ecomm/copy/FormatText';
import { toDollars } from '@ecomm/models';

type Props = {
  isDiscountedPrice: boolean;
  lineItemBasePrice: any;
  itemTotalPrice: number;
};

export const CartItemPrice: React.FC<React.PropsWithChildren<Props>> = ({
  isDiscountedPrice,
  lineItemBasePrice,
  itemTotalPrice,
}) => {
  return (
    <Flex>
      <Label
        data-test-id="item-price"
        size="large"
        textColor={isDiscountedPrice ? red[50] : grey[90]}
        tabIndex={0}
      >
        <FormatText
          data-test-id="product-info-current-price"
          content="{price, number, currencyWithCents}"
          values={{ price: toDollars(lineItemBasePrice) }}
        />
      </Label>
      {isDiscountedPrice && (
        <StyledStrikethroughPrice
          data-test-id="item-discounted-price"
          size="large"
          textColor={grey[70]}
        >
          <FormatText
            data-test-id="product-info-real-price"
            content="{price, number, currencyWithCents}"
            values={{ price: toDollars(itemTotalPrice) }}
          />
        </StyledStrikethroughPrice>
      )}
    </Flex>
  );
};

const StyledStrikethroughPrice = styled(Label)`
  text-decoration-line: line-through;
  padding-left: ${spacing[8]};
`;
