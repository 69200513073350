import useCartWithVariables from '@ecomm/cart-next/hooks/useCartWithVariables';
import type { CfuBundleItem } from '@ecomm/graphql/types.generated';
import { colorBikes } from '@page-builder/modules/Overview/constants';

function isCfuBundle(item: any): item is CfuBundleItem {
  return item.__typename === 'CfuBundleItem';
}

const useCartContainsColoredBike = () => {
  const items = useCartWithVariables().data?.cart?.items ?? [];

  return items.some(item => isCfuBundle(item) && !!colorBikes[item.slug]);
};

export default useCartContainsColoredBike;
