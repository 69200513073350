import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { driftExists, startInteraction } from '@peloton/drift/utils';
import { VIRTUAL_SHOWROOM_IDS, useLocale } from '@peloton/internationalize';
import useNav from '@content/client/www/nav/useNav';
import { b4 } from '@ecomm/typography';

type Props = {
  hideMenu: VoidFunction;
};

const VirtualShowroomLink: React.FC<React.PropsWithChildren<Props>> = ({ hideMenu }) => {
  const cmsId = 'nav.geo.virtual';
  const locale = useLocale();
  const virtualShowroomId = VIRTUAL_SHOWROOM_IDS[locale];
  const { trackEvent } = useTracking();
  const { content, isLoading } = useNav(locale);

  const virtualAppt = content.geoMenu?.['navigation.geoMenu']?.[cmsId];

  if (isLoading) {
    return null;
  }

  if (driftExists() && virtualShowroomId) {
    const handleClick = () => {
      startInteraction(virtualShowroomId);
      hideMenu();
    };

    return (
      <li>
        <MenuLink
          onClick={() => {
            handleClick();
            trackEvent({
              properties: {
                category: 'location',
                unitName: cmsId,
                linkName: virtualAppt.text,
              },
            });
          }}
        >
          {virtualAppt.text}
        </MenuLink>
      </li>
    );
  }

  return null;
};

export default VirtualShowroomLink;

const MenuLink = styled.button`
  ${b4}
`;
