import {
  Container,
  Flex,
  red,
  spacing,
  Icon,
  Eyebrow,
} from '@pelotoncycle/design-system';
import React from 'react';
import CartContent from '@ecomm/cart-next/shared/CartContent';
import { LinkButton } from '@ecomm/internationalize-ui';
import useCartContent from '../shared/useCartContent';

const LoadingCartView: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Container padding={spacing[32]}>
      <Eyebrow size="large" marginBottom={spacing[16]}>
        <CartContent field="title" />
      </Eyebrow>
      <Flex minHeight={spacing[96]} justifyContent="center" alignItems="center">
        <Icon
          height={30}
          primaryColor={red[80]}
          name="spinner"
          data-test-id="cart-panel-loading-spinner"
        />
      </Flex>
      <LinkButton
        width="adaptive"
        data-test-id="shop-now-cta"
        href="/shop/bike"
        text={useCartContent('shopNow')}
      />
    </Container>
  );
};

export default LoadingCartView;
