// THIS IS AN AUTOGENERATED FILE (@ecomm/commercetools/graphql/codegen.yml). DO NOT EDIT THIS FILE DIRECTLY.
import * as Types from '../types.generated';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
export type GetCategoryByKeyQueryVariables = Types.Exact<{
  key: Types.Scalars['String'];
  acceptLanguage?: Types.Maybe<Array<Types.Scalars['Locale']>>;
}>;

export type GetCategoryByKeyQuery = { __typename?: 'Query' } & {
  category?: Types.Maybe<
    { __typename?: 'Category' } & Pick<
      Types.Category,
      'id' | 'key' | 'name' | 'orderHint'
    > & {
        children?: Types.Maybe<
          Array<
            { __typename?: 'Category' } & Pick<
              Types.Category,
              'id' | 'key' | 'name' | 'orderHint'
            >
          >
        >;
      }
  >;
};

export const GetCategoryByKeyDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCategoryByKey' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
          directives: [],
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'acceptLanguage' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Locale' } },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'key' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'key' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'acceptLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'acceptLanguage' },
                      },
                    },
                  ],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderHint' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'children' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'key' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'name' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'acceptLanguage' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'acceptLanguage' },
                            },
                          },
                        ],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'orderHint' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useGetCategoryByKeyQuery__
 *
 * To run a query within a React component, call `useGetCategoryByKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryByKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryByKeyQuery({
 *   variables: {
 *      key: // value for 'key'
 *      acceptLanguage: // value for 'acceptLanguage'
 *   },
 * });
 */
export function useGetCategoryByKeyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCategoryByKeyQuery,
    GetCategoryByKeyQueryVariables
  >,
) {
  return Apollo.useQuery<GetCategoryByKeyQuery, GetCategoryByKeyQueryVariables>(
    GetCategoryByKeyDocument,
    baseOptions,
  );
}
export function useGetCategoryByKeyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCategoryByKeyQuery,
    GetCategoryByKeyQueryVariables
  >,
) {
  return Apollo.useLazyQuery<GetCategoryByKeyQuery, GetCategoryByKeyQueryVariables>(
    GetCategoryByKeyDocument,
    baseOptions,
  );
}
export type GetCategoryByKeyQueryHookResult = ReturnType<typeof useGetCategoryByKeyQuery>;
export type GetCategoryByKeyLazyQueryHookResult = ReturnType<
  typeof useGetCategoryByKeyLazyQuery
>;
export type GetCategoryByKeyQueryResult = Apollo.QueryResult<
  GetCategoryByKeyQuery,
  GetCategoryByKeyQueryVariables
>;
