import { Flex, Label, black } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import useCartContent from '@content/client/www/cart/useCart';
import FormatText from '@ecomm/copy/FormatText';
import { toDollars } from '@ecomm/models';
import CartMarkdownContent from './shared/CartMarkdownContent';
import CartTooltip from './shared/CartTooltip';

const StyledCaseLabel = styled(Label)`
  text-transform: lowercase;
  ::first-letter {
    text-transform: capitalize;
  }
`;

type Props = {
  tax: number;
  textColor?: string;
};
const Tax: React.FC<React.PropsWithChildren<Props>> = ({ tax, textColor }) => {
  const { content: cartContent } = useCartContent();
  const displayColor = textColor ?? black;
  return (
    <Flex justifyContent="space-between">
      <Flex>
        <StyledCaseLabel size="large" data-test-id="tax-label" textColor={displayColor}>
          {cartContent?.estimatedTax?.value}
        </StyledCaseLabel>
        <CartTooltip dataTestId="estimated-tax-tooltip">
          <CartMarkdownContent
            title={cartContent?.taxEstimation?.value}
            body={cartContent?.taxEstimationDetail?.value}
          />
        </CartTooltip>
      </Flex>
      <Label size="large" as="span" textColor={displayColor}>
        {tax > 0 ? (
          <FormatText
            data-test-id="estimated-tax"
            content="{price, number, currencyWithCents}"
            values={{ price: toDollars(tax) }}
          />
        ) : (
          '--'
        )}
      </Label>
    </Flex>
  );
};

export default Tax;
