import { Container, spacing, Flex, Label } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import LeasingBundlePrice from '@ecomm/cart-next/cart-summary/cart-items/LeasingBundlePrice';
import type { Money } from '@ecomm/models';
import ItemDescriptionView from './ItemDescriptionView';
import ItemPriceView from './ItemPriceView';
import ItemsInBundleView from './ItemsInBundleView';
import ItemTitleView from './ItemTitleView';

type Props = {
  className?: string;
  name: string;
  itemQty: number;
  itemPrice: Money;
  bundleItems: any[];
  kind?: string;
  description: string;
  optionId: string;
  slug?: string;
};

const CartItemView: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  name,
  itemQty,
  itemPrice,
  bundleItems,
  kind,
  description,
  optionId,
  slug,
}) => {
  const isMembership = kind === 'MembershipItem';
  const isLeasingBundle = kind === 'LeasingBundleItem';

  return (
    <Container className={className}>
      <Flex>
        <QuantityLabel size="large">{itemQty}</QuantityLabel>
        <Flex flexGrow={1}>
          <ItemTitleView name={name} isMembership={isMembership} />
        </Flex>
        {!isLeasingBundle && (
          <StyledItemPriceView
            priceInCents={itemPrice}
            isMembership={isMembership}
            isLeasingBundle={isLeasingBundle}
          />
        )}
      </Flex>
      <Row justifyContent="space-between" alignItems="flex-start">
        <Flex flexDirection="column">
          <StyledItemsInBundleView bundleItems={bundleItems} />
          <StyledItemDescriptionView
            description={description}
            showDescription={isMembership}
          />
          {isLeasingBundle && (
            <StyledLeasingBundlePrice priceInCents={itemPrice} slug={slug} />
          )}
        </Flex>
      </Row>
    </Container>
  );
};

const StyledItemPriceView = styled(ItemPriceView)`
  margin-left: 10px;
`;

const Row = styled(Flex)`
  margin-bottom: ${spacing[24]};
`;

const StyledItemsInBundleView = styled(ItemsInBundleView)`
  margin-left: ${spacing[16]};
`;

const StyledItemDescriptionView = styled(ItemDescriptionView)`
  margin-left: ${spacing[16]};
`;

const StyledLeasingBundlePrice = styled(LeasingBundlePrice)`
  margin-left: ${spacing[16]};
`;

const QuantityLabel = styled(Label)`
  margin-right: ${spacing[8]};
`;

export default CartItemView;
