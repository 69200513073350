import { useCallback } from 'react';
import { useTracking } from 'react-tracking';
import { isBundleItem } from '@ecomm/cart-next/cart-summary/cart-items/models';
import type { CartFragment } from '@ecomm/cart-next/graphql/fragments/Cart.generated';
import { toDollars } from '@ecomm/models';
import type { Option } from '../hooks/useGetPrepaidMembershipOptions';
import { toSegmentProducts } from './segmentMappings';

const toGiftSubscriptionSelectedEvent = (cart: CartFragment, subscription: Option) => {
  const cartItems = cart?.items ?? [];
  return {
    event: 'Gift Subscription Selected',
    properties: {
      cartId: cart.id,
      hasTradeIn: (cart.numberOfTradeIns ?? 0) > 0,
      hasAccessory: cart.hasAccessories,
      hasGuide: cart.hasGuide,
      hasCPO: cart.hasRefurbBike || cart.hasRefurbBikePlus,
      hasOPC: cart.hasEquipmentLease,
      position: 'cart',
      addedFromCart: true,
      name: subscription.name,
      price: toDollars(subscription.price ? subscription.price : 0),
      quantity: 1,
      id: subscription.value,
      sku: subscription.skus ? subscription.skus.join(',') : '',
      slug: subscription.productSlug,
      category: 'Peloton All Access Membership',
      products: toSegmentProducts(cartItems),
      hasAccessoryBundle: cartItems.some(i => i.__typename == 'AccessoryBundleItem'),
      accessoryName: String(
        cartItems.filter(i => i.__typename == 'ProductItem').map(i => i.name),
      ),
      bundleName: cartItems.filter(i => isBundleItem(i)).map(i => i.name),
      subscriptionDuration: subscription.months ? `${subscription.months} months` : null,
    },
  };
};

const useTrackGiftSubscriptionSelected = () => {
  const { trackEvent } = useTracking();
  return useCallback(
    (cart: any, option: Option) => {
      const event = toGiftSubscriptionSelectedEvent(cart, option);
      trackEvent(event);
    },
    [trackEvent],
  );
};

export default useTrackGiftSubscriptionSelected;
