import { Flex, Label, black } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import useCartContent from '@content/client/www/cart/useCart';
import PriceWithCents from '@ecomm/cart-next/shared/PriceWithCents';

const StyledCaseLabel = styled(Label)`
  text-transform: lowercase;
  ::first-letter {
    text-transform: capitalize;
  }
`;

type Props = {
  total: number;
  shippingAddress?: {} | null;
};

const Total: React.FC<React.PropsWithChildren<Props>> = ({ total, shippingAddress }) => {
  const { content: cartContent } = useCartContent();
  return (
    <Flex justifyContent="space-between">
      <StyledCaseLabel size="large" data-test-id="order-total" textColor={black}>
        {shippingAddress
          ? cartContent?.totalLabel?.value
          : cartContent?.estimatedTotalLabel?.value}
      </StyledCaseLabel>
      <Label size="extraLarge" as="span" textColor={black}>
        <PriceWithCents amount={total} />
      </Label>
    </Flex>
  );
};

export default Total;
