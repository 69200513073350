import {
  Flex,
  Eyebrow,
  spacing,
  Support,
  SingleSelect,
  Button,
  FlexChild,
  Container,
  white,
  grey,
} from '@pelotoncycle/design-system';
import type { Ref } from 'react';
import React, { useEffect, useState, forwardRef } from 'react';
import styled from 'styled-components';
import useTrackGiftSubscriptionSelected from '@ecomm/cart-next/analytics/useTrackGiftSubscriptionSelected';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import { EquipmentType } from '@ecomm/graphql/types.generated';
import useAddProductToCartAnalytics from '@ecomm/shop/graphql/useAddProductToCartAnalytics';
import { useSetIsCartPanelDisabled } from '../context/CartContext';
import { useAddItemToCartMutation } from '../graphql/mutations/AddItem.generated';
import useCartWithVariables from '../hooks/useCartWithVariables';
import useGetCfuTypesInCart from '../hooks/useGetCfuTypesInCart';
import useGetNumberOfPrepaidCreditsInCart from '../hooks/useGetNumberOfPrepaidCreditsInCart';
import useGetPrepaidMembershipOptions from '../hooks/useGetPrepaidMembershipOptions';
import type { Option } from '../hooks/useGetPrepaidMembershipOptions';
import useIsCartEligibleForGifting from '../hooks/useIsCartEligibleForGifting';
import CartMarkdownContent from '../shared/CartMarkdownContent';
import GiftingContent from '../shared/GiftingContent';
import useGiftingContent from '../shared/useGiftingContent';

const AddAllAccessMembership = forwardRef((_, ref?: Ref<HTMLDivElement>) => {
  const isToggleActive = useIsToggleActive();
  const [selectedMembership, setSelectedMembership] = useState<Option>();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const selectPlaceholder = useGiftingContent('addAllAccessMembershipSelectDuration');
  const disclaimer = useGiftingContent('addAllAccessMembershipDisclaimer');
  const options = useGetPrepaidMembershipOptions();
  const isCartEligibleForGifting = useIsCartEligibleForGifting();
  const cfuTypesInCart = useGetCfuTypesInCart();
  const cart = useCartWithVariables().data?.cart;
  const trackSelectedSubscription = useTrackGiftSubscriptionSelected();
  const numberOfCreditsInCart = useGetNumberOfPrepaidCreditsInCart();

  const [addItemMutation] = useAddItemToCartMutation();
  const setIsCartDisabled = useSetIsCartPanelDisabled();

  const isProjectPhoenixEnabled = isToggleActive('projectPhoenix');

  const hasOnlyGuide = cfuTypesInCart.every(cfuType => cfuType === EquipmentType.Guide);
  const isCartEligibleForPrepaidAAM = isCartEligibleForGifting && !hasOnlyGuide;

  const cartId = cart?.id ?? '';
  const cartHasPrepaidCredit = cart?.hasPrepaidCredit ?? false;
  const { setCartId, setProductSelection } = useAddProductToCartAnalytics(
    true,
    'Peloton All Access Membership',
  );

  const addItem = async () => {
    if (selectedMembership) {
      setIsButtonDisabled(true);
      setIsCartDisabled(true);
      await addItemMutation({
        variables: {
          optionId: selectedMembership.value,
          cartId: cartId,
          calculateEstimatedShippingPrice: isProjectPhoenixEnabled,
        },
      });
      setIsCartDisabled(false);
      const variables = {
        product: selectedMembership.productSlug,
        selections: [
          {
            attribute: selectedMembership.configuration[0].attribute,
            option: selectedMembership.configuration[0].option,
          },
        ],
      };
      setCartId(cartId);
      setProductSelection({ ...variables });
    }
  };

  useEffect(() => {
    // When credits are removed from cart, reset select input to default
    if (!cartHasPrepaidCredit) {
      setSelectedMembership(undefined);
    }
  }, [cartHasPrepaidCredit]);

  useEffect(() => {
    if (selectedMembership && !cartHasPrepaidCredit) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [selectedMembership, cartHasPrepaidCredit]);

  if (options.length == 0 || !isCartEligibleForPrepaidAAM) return null;

  return (
    <Container
      margin={`${spacing[16]} 0 0 0`}
      backgroundColor={white}
      borderRadius={spacing[4]}
      padding={`${spacing[24]} ${spacing[16]}`}
      ref={ref}
    >
      <Eyebrow size="medium">
        <GiftingContent field="addAllAccessMembershipTitle" />
      </Eyebrow>
      <Container padding={`${spacing[4]} 0 ${spacing[16]} 0`}>
        <Support size="medium">
          <GiftingContent field="addAllAccessMembershipDescription" />
        </Support>
      </Container>

      <Flex gap={spacing[8]}>
        <FlexChild flexGrow={1}>
          <SingleSelect
            handleSelectedItemChange={(chosenItem: Option) => {
              setSelectedMembership(chosenItem);
              if (cart && chosenItem) {
                trackSelectedSubscription(cart, chosenItem);
              }
            }}
            items={options}
            label={selectPlaceholder}
            selectedItem={
              selectedMembership ||
              options.find(option => option.months === numberOfCreditsInCart.toString())
            }
            disabled={cartHasPrepaidCredit}
          />
        </FlexChild>
        <FlexChild>
          <Button
            size="large"
            color={isButtonDisabled ? 'primary' : 'dark'}
            variant="solid"
            style={{ width: '76px' }}
            isDisabled={isButtonDisabled}
            onClick={() => addItem()}
            data-test-id="addAAMButton"
          >
            <GiftingContent
              field="addAllAccessMembershipCta"
              values={{ itemAdded: cartHasPrepaidCredit }}
            />
          </Button>
        </FlexChild>
      </Flex>
      <Container padding={`${spacing[16]} 0 0 0`}>
        <Support textColor={grey[70]}>
          <CartMarkdownContent field={disclaimer} renderers={{ link: DisclaimerLink }} />
        </Support>
      </Container>
    </Container>
  );
});

const DisclaimerLink = styled.a.attrs({ target: '_blank' })``;

AddAllAccessMembership.displayName = 'AddAllAccessMembership';

export default AddAllAccessMembership;
