//eslint-disable-next-line
import { spacing, Container } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import useCartContent from '@content/client/www/cart/useCart';
import { LinkButton } from '@ecomm/internationalize-ui';

interface Props {
  children: React.ReactNode;
  showLoader: boolean;
}

const BasePanelView: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  showLoader,
}) => {
  const { content: cartContent } = useCartContent();
  return (
    <StyledCartContainer showLoader={showLoader}>
      {children}
      <LinkButton width="adaptive" data-test-id="shop-now-cta" href="/">
        {cartContent?.shopNow?.value}
      </LinkButton>
    </StyledCartContainer>
  );
};

export default BasePanelView;

const StyledCartContainer = styled(Container)<{ showLoader: boolean }>`
  position: relative;
  padding: ${props =>
    props.showLoader ? `${spacing[32]}` : `0 ${spacing[32]} ${spacing[32]}`};
`;
