import {
  brand,
  Flex,
  grey,
  Input,
  spacing,
  Icon,
  ErrorMsg,
} from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { b4, reg, l1 } from '@ecomm/typography';
import CartContent from '../../shared/CartContent';

type Props = {
  isOpen: boolean;
  setIsOpenTrue: () => void;
  label: string;
  onSubmit: () => void;
  value: string;
  onChange: (_: React.FormEvent<HTMLInputElement>) => void;
  isLoading: boolean;
  promoCodeErrorMessage?: string;
};

const AddPromoCodeView: React.FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  setIsOpenTrue,
  label,
  onSubmit,
  value,
  onChange,
  isLoading,
  promoCodeErrorMessage,
}) => {
  return !isOpen ? (
    <StyledAddCoupon data-test-id="revealCouponCodeInput" onClick={setIsOpenTrue}>
      <CartContent field="enterPromoCode" />
    </StyledAddCoupon>
  ) : (
    <>
      <Flex
        alignItems="center"
        verticalMargin={`${spacing[8]} 0`}
        as="form"
        onSubmit={e => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <Input
          data-test-id="promo-code-input"
          disabled={isLoading}
          label={label}
          name="cart-promo-code"
          width={'170px'}
          onChange={onChange}
          value={value}
        />
        <ApplyButton type="submit" disabled={isLoading} data-test-id="applyCouponCode">
          <CartContent field="apply" />
        </ApplyButton>
        {isLoading && <Icon data-test-id="loading-spinner" name="spinner" />}
      </Flex>
      {promoCodeErrorMessage && (
        <ErrorMsg marginTop={spacing[8]}>{promoCodeErrorMessage}</ErrorMsg>
      )}
    </>
  );
};

const ApplyButton = styled.button`
  ${b4}
  ${reg}
  ${l1}
  padding-left: 15px;
  padding-right: 10px;
`;

const StyledAddCoupon = styled.button`
  ${b4}
  ${reg}
  color: ${brand.darkest};
  text-decoration: underline;
  &:hover {
    color: ${grey[70]};
  }
`;

export default AddPromoCodeView;
