import { EquipmentType } from '@ecomm/graphql/types.generated';
import useCartWithVariables from './useCartWithVariables';

const useGetCfuTypesInCart = () => {
  const cart = useCartWithVariables().data?.cart;

  if (!cart) return [];

  const cfuTypes: EquipmentType[] = [];

  // OTD: Determine a sort priority since only the first element is sent for calculation
  if (cart.hasRefurbBike) {
    cfuTypes.push(EquipmentType.Refurbbike);
  }
  if (cart.hasRefurbBikePlus) {
    cfuTypes.push(EquipmentType.Refurbbikeplus);
  }
  if (cart.hasBike) {
    cfuTypes.push(EquipmentType.Bike);
  }
  if (cart.hasBikePlus) {
    cfuTypes.push(EquipmentType.Bikeplus);
  }
  if (cart.hasTread) {
    cfuTypes.push(EquipmentType.Tread);
  }
  if (cart.hasTreadPlus) {
    cfuTypes.push(EquipmentType.Treadplus);
  }
  if (cart.hasGuide) {
    cfuTypes.push(EquipmentType.Guide);
  }
  if (cart.hasRow) {
    cfuTypes.push(EquipmentType.Row);
  }

  return cfuTypes;
};

export default useGetCfuTypesInCart;
