import { Body, Container, Eyebrow, grey, spacing } from '@pelotoncycle/design-system';
import React from 'react';
import { LinkButton } from '@ecomm/internationalize-ui';
import CartContent from '../shared/CartContent';
import useCartContent from '../shared/useCartContent';

const EmptyCartView: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Container padding={spacing[32]}>
      <Eyebrow size="large" marginBottom={spacing[16]}>
        <CartContent field="title" />
      </Eyebrow>
      <Body
        data-test-id="emptyCartDescription"
        size="medium"
        is="h2"
        marginBottom={spacing[64]}
        textColor={grey[70]}
      >
        <CartContent field="emptyCart" />
      </Body>
      <LinkButton
        width="adaptive"
        data-test-id="shop-now-cta"
        href="/shop/bike"
        text={useCartContent('shopNow')}
      />
    </Container>
  );
};

export default EmptyCartView;
