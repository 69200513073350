import {
  Container,
  Flex,
  grey,
  Label,
  spacing,
  Support,
} from '@pelotoncycle/design-system';
import React from 'react';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import useCartWithVariables from '../../hooks/useCartWithVariables';
import CartContent from '../../shared/CartContent';
import CartMarkdownContent from '../../shared/CartMarkdownContent';
import CartTooltip from '../../shared/CartTooltip';
import infoMarkdownProps from '../../shared/InfoMarkdownProps';

const AllAccessMembershipView: React.FC<React.PropsWithChildren<unknown>> = () => {
  const cart = useCartWithVariables().data?.cart;
  const isGiftingEnhancementActive = useIsToggleActive()('giftingCheckoutWithoutAam');
  const hasEquipmentWithNoMembership = cart && cart.hasEquipment && !cart.hasMembership;
  const isGift = cart?.isGift;
  const hasPrepaidCredit = cart?.hasPrepaidCredit;
  const shouldDisplayAAM =
    isGiftingEnhancementActive &&
    hasEquipmentWithNoMembership &&
    isGift &&
    !hasPrepaidCredit;

  return shouldDisplayAAM ? (
    <Container horizontalPadding={`0 ${spacing[24]}`}>
      <Flex>
        <Label data-test-id="item-name" size="large">
          <CartContent field="membershipName" />
        </Label>
        <CartTooltip dataTestId="tooltip">
          <CartMarkdownContent field="membershipInfo" renderers={infoMarkdownProps} />
        </CartTooltip>
      </Flex>
      <Container maxWidth="256px">
        <Support size="small" textColor={grey[70]}>
          <CartContent field="membershipDescription" />
        </Support>
      </Container>
    </Container>
  ) : null;
};

export default AllAccessMembershipView;
