import React from 'react';
import useCartOtd from '@content/client/www/otd/useCartOtd';
import OtdEstimate from '@ecomm/cart/summary/OtdEstimate';
import useCartWithVariables from '../hooks/useCartWithVariables';

const Otd: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { isLoading: isContentLoading } = useCartOtd();
  const cart = useCartWithVariables().data?.cart;

  if (isContentLoading) {
    return null;
  }
  return cart?.hasBike ||
    cart?.hasBikePlus ||
    cart?.hasTread ||
    cart?.hasRow ||
    cart?.hasEquipmentLease ||
    cart?.hasRefurbBike ||
    cart?.hasRefurbBikePlus ? (
    <OtdEstimate />
  ) : null;
};

export default Otd;
