import React from 'react';
import styled from 'styled-components';
import { useErrorReporter } from '@peloton/error-reporting';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import { useSetIsCartPanelDisabled } from '../../context/CartContext';
import { useRemoveItemFromCartMutation } from '../../graphql/mutations/RemoveItem.generated';
import { useCartQuery } from '../../graphql/queries/Cart.generated';
import RemoveButton from '../../shared/RemoveButton';

type Props = {
  optionId?: string;
  className?: string;
};

const RemoveItem: React.FC<React.PropsWithChildren<Props>> = ({
  optionId,
  className,
}) => {
  const isProjectPhoenixEnabled = useIsToggleActive()('projectPhoenix');
  const { data, refetch } = useCartQuery({
    variables: { calculateEstimatedShippingPrice: isProjectPhoenixEnabled },
    suspend: false,
  });
  const [removeItemMutation] = useRemoveItemFromCartMutation();
  const setIsCartDisabled = useSetIsCartPanelDisabled();
  const { errorReporter } = useErrorReporter();

  const removeItem = async () => {
    setIsCartDisabled(true);
    try {
      await removeItemMutation({
        variables: {
          optionId: optionId,
          cartId: data?.cart?.id ?? '',
          calculateEstimatedShippingPrice: isProjectPhoenixEnabled,
        },
      });
    } catch (err) {
      errorReporter.reportError(new Error(`Failed useRemoveItemFromCartMutation ${err}`));
      await refetch();
    }
    setIsCartDisabled(false);
  };

  return (
    <RemoveButton
      className={className}
      size="medium"
      onClick={() => {
        removeItem();
      }}
    />
  );
};

const RemoveItemButton = styled(RemoveItem)``;

export default RemoveItemButton;
