import {
  Card,
  Eyebrow,
  Flex,
  Support,
  spacing,
  Container,
} from '@pelotoncycle/design-system';
import React from 'react';
import useCartWithVariables from '../hooks/useCartWithVariables';
import CartContent from '../shared/CartContent';
import QuantityView from './cart-items/QuantityView';
import RemoveItem from './cart-items/RemoveItem';

type Props = { className?: string };

const TradeIn: React.FC<React.PropsWithChildren<Props>> = ({ className }) => {
  const quantity = useCartWithVariables().data?.cart?.numberOfTradeIns ?? 0;

  return quantity > 0 ? (
    <Card className={className}>
      <Flex justifyContent="space-between">
        <div>
          <Eyebrow size="medium" display="block" marginBottom={spacing[16]}>
            <CartContent field="tradeInTitle" />
          </Eyebrow>
          <Support size="medium" display="block">
            <CartContent field="tradeInDescription" values={{ quantity }} />
          </Support>
        </div>
        <Container textAlign="right">
          <QuantityView quantity={quantity} />
          <RemoveItem />
        </Container>
      </Flex>
    </Card>
  ) : null;
};

export default TradeIn;
