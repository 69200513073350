import { grey, Support } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';

type Props = {
  description?: string;
  showDescription: boolean;
  className?: string;
  isPrepaidCredits?: boolean;
};

const ItemDescriptionView: React.FC<React.PropsWithChildren<Props>> = ({
  description,
  showDescription,
  className,
}) => {
  return showDescription ? (
    <StyledSubItems className={className} size="small" textColor={grey[70]}>
      {description}
    </StyledSubItems>
  ) : null;
};

const StyledSubItems = styled(Support)`
  margin-left: 0px;
  color: ${grey[70]};
  max-width: 256px;
`;

export default ItemDescriptionView;
