import { Icon, red, spacing, grey } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';

const CartPanelOverlay: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <>
      <Overlay />
      <CartPanelLoader overlay />
    </>
  );
};

export const CartPanelLoader: React.FC<
  React.PropsWithChildren<{ overlay?: boolean }>
> = ({ overlay = false }) => {
  return (
    <StyledSpinnerContainer data-test-id="cart-container-overlay" overlay={overlay}>
      <StyledSpinner data-test-id="cart-panel-loading-spinner" />
    </StyledSpinnerContainer>
  );
};

export default CartPanelOverlay;

export const StyledSpinner = styled(Icon).attrs({
  height: 30,
  primaryColor: red[80],
  name: 'spinner',
})`
  position: relative;
  width: 100%;
`;

export const StyledSpinnerContainer = styled.div<{ overlay: boolean }>`
  position: fixed;
  width: 100%;
  left: 0;
  top: ${props => (props.overlay ? '50%' : 'initial')};
  padding: ${spacing[16]} 0;
  background: ${props => (props.overlay ? 'initial' : `${grey[30]}`)};
`;

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.6);
`;
