import {
  Flex,
  Support,
  grey,
  spacing,
  Eyebrow,
  black,
  white,
  SingleSelect,
  Button,
} from '@pelotoncycle/design-system';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import CartMarkdownContent from '@ecomm/cart-next/shared/CartMarkdownContent';
import GiftingContent from '@ecomm/cart-next/shared/GiftingContent';
import useGiftingContent from '@ecomm/cart-next/shared/useGiftingContent';
import useGetCartItems from '@ecomm/commercetools/hooks/useGetCartItems';
import type { Currency } from '@ecomm/graphql/types.generated';
import useTrackGiftSubscriptionSelected from '@ecomm/pg-checkout-commercetools/analytics/useCTGiftSubscriptionSelected';
import {
  isCartEligibleForPrepaidMembership,
  isPrepaidMembership,
  getPrepaidAAMProduct,
} from '@ecomm/pg-checkout-commercetools/helpers/ct-cart-helper';
import { useGetPrepaidGiftSubscriptionsForCart } from '@ecomm/pg-checkout-commercetools/hooks/useGetPrepaidGiftSubscriptionsForCart';
import { useAddItemToCart } from '@ecomm/pg-checkout-commercetools/utils/cartUtils';

const StyledContainer = styled.div`
  margin-top: ${spacing[16]};
  background-color: ${white};
  border-radius: ${spacing[4]};
  padding: ${spacing[24]} ${spacing[16]};
  color: ${black};
`;

const StyledEyebrow = styled(Eyebrow)`
  margin-bottom: ${spacing[8]};
`;

const StyledSelectWrapper = styled(Flex)`
  margin: ${spacing[16]} 0;
  > div {
    flex: 1;
  }
`;

const StyledButton = styled(Button)`
  margin-left: ${spacing[8]};
`;

const DisclaimerLink = styled.a.attrs({ target: '_blank' })``;

type AddItem = {
  sku?: string;
};

export type Attributes = {
  attribute: string;
  legacyOptionId: string;
  option: string;
};

export type Option = {
  configurations: Attributes[];
  description: string;
  discountPrice?: { amount: any; currency: any };
  key: string;
  price?: { amount: number; currency: Currency };
  sku: string;
  option: string;
  value: string;
  productKey: string;
};

export const handleClick = (
  setIsButtonDisabled: (arg: boolean) => void,
  addItemToCart: (args: AddItem) => void,
  membershipDuration?: Option,
) => {
  setIsButtonDisabled(true);

  addItemToCart({
    sku: membershipDuration?.sku ?? '',
  });
};

const GiftingMembership: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { trackCtGiftSubscriptionSelected } = useTrackGiftSubscriptionSelected();
  const options = useGetPrepaidGiftSubscriptionsForCart();

  const { data: shopCart } = useGetCartItems();
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [membershipDuration, setMembershipDuration] = useState<Option>();
  const { addItemToCart } = useAddItemToCart();

  const hasPrepaidMembershipInCart =
    shopCart?.lineItems.some(lineItem => isPrepaidMembership(lineItem)) ?? false;
  const isCartEligible = isCartEligibleForPrepaidMembership(shopCart?.lineItems ?? []);

  const handleDurationChange = (chosenItem: Option | undefined) => {
    setMembershipDuration(chosenItem);
    setIsButtonDisabled(chosenItem === undefined);
    if (chosenItem) {
      trackCtGiftSubscriptionSelected(chosenItem, shopCart);
    }
  };

  useEffect(() => {
    if (hasPrepaidMembershipInCart) {
      setIsButtonDisabled(true);
    } else {
      // When prepaid membership is removed from cart, reset select input to default
      setMembershipDuration(undefined);
    }
  }, [hasPrepaidMembershipInCart]);

  useEffect(() => {
    const hasOptions = !!options?.length;

    if (hasPrepaidMembershipInCart && hasOptions) {
      const existingAllAccess = getPrepaidAAMProduct(shopCart?.lineItems ?? []);

      if (!!existingAllAccess && !membershipDuration) {
        const existingDuration = existingAllAccess?.productVariant?.size ?? '';
        const matchingOption = options.find(
          option =>
            option.configurations.find(
              configuration => configuration.attribute === 'subscription-size',
            )?.option === existingDuration,
        );

        if (matchingOption) {
          setMembershipDuration(matchingOption);
        }
      }
    }
  }, [shopCart, membershipDuration, options, hasPrepaidMembershipInCart]);

  const disclaimer = useGiftingContent('addAllAccessMembershipDisclaimer');

  if (options.length == 0 || !isCartEligible) return null;

  return (
    <StyledContainer>
      <StyledEyebrow
        size="medium"
        textColor={black}
        data-test-id="gifting-membership-label"
      >
        <GiftingContent field="addAllAccessMembershipTitle" />
      </StyledEyebrow>
      <Support size="medium" textColor={grey[90]} data-test-id="gifting-membership-desc">
        <GiftingContent field="addAllAccessMembershipDescription" />
      </Support>
      {(!hasPrepaidMembershipInCart || membershipDuration) && (
        <StyledSelectWrapper>
          <SingleSelect
            items={options}
            label="Select duration"
            handleSelectedItemChange={(chosenItem: Option) => {
              handleDurationChange(chosenItem);
            }}
            selectedItem={membershipDuration}
            disabled={hasPrepaidMembershipInCart}
            data-test-id="gifting-membership-duration"
          />
          <StyledButton
            color={isButtonDisabled ? 'primary' : 'dark'}
            isDisabled={isButtonDisabled}
            variant="solid"
            size="large"
            width="adjustable"
            onClick={() => {
              handleClick(setIsButtonDisabled, addItemToCart, membershipDuration);
            }}
            data-test-id="gifting-membership-add-button"
          >
            <GiftingContent
              field="addAllAccessMembershipCta"
              values={{ itemAdded: hasPrepaidMembershipInCart }}
            />
          </StyledButton>
        </StyledSelectWrapper>
      )}
      <Support size="small" textColor={grey[70]} data-test-id="gifting-membership-terms">
        <CartMarkdownContent field={disclaimer} renderers={{ link: DisclaimerLink }} />
      </Support>
    </StyledContainer>
  );
};

export default GiftingMembership;
