import { Button, spacing } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import useCartContent from '@content/client/www/cart/useCart';

const StyledButtonContainer = styled.div`
  background-color: rgb(245, 247, 249);
  position: sticky;
  bottom: 0px;
  height: 100%;
  -webkit-box-flex: 1;
  flex-grow: 1;
  padding: ${spacing[16]};
  filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 20px 30px);

  ${media.tabletXLarge`
    padding: ${spacing[16]} ${spacing[24]};
  `}
`;

type Props = {
  isDisabled: boolean;
  handleClick: () => void;
};

const CheckoutButton: React.FC<React.PropsWithChildren<Props>> = ({
  isDisabled,
  handleClick,
}) => {
  const { content: cartContent } = useCartContent();

  return (
    <StyledButtonContainer>
      <Button
        icon="lockInteraction"
        width="adaptive"
        data-test-id="checkoutLink"
        text={cartContent?.checkout?.value}
        onClick={handleClick}
        isDisabled={isDisabled}
      />
    </StyledButtonContainer>
  );
};

export default CheckoutButton;
