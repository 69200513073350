import { Body, grey, spacing } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import useCartContent from '@content/client/www/cart/useCart';
import BasePanelView from './BasePanelView';
import { CartPanelLoader } from './CartPanelOverlay';

const EmptyCart = () => {
  const { content: cartContent } = useCartContent();
  return (
    <EmptyCartText
      data-test-id="emptyCartDescription"
      size="medium"
      is="h2"
      textColor={grey[70]}
    >
      {cartContent?.emptyCart?.value}
    </EmptyCartText>
  );
};

const EmptyCartView: React.FC<React.PropsWithChildren<{ showLoader: boolean }>> = ({
  showLoader,
}) => {
  return (
    <BasePanelView showLoader={showLoader}>
      {showLoader && <CartPanelLoader />}
      <EmptyCart />
    </BasePanelView>
  );
};

export default EmptyCartView;

const EmptyCartText = styled(Body)`
  margin-bottom: ${spacing[64]};
`;
