import { UMICH_PACKAGE_SLUG } from '@ecomm/cart-next/hooks/useIsCartEligibleForFinancing';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';

import {
  COMMERCETOOLS_AVAILABILITY_FF,
  COMMERCETOOLS_CFU_ACCESSORIES,
} from '../constants';

interface Options {
  slug?: string;
}

export const useIsCTAvailabilityActive = (opts: Options = {}) => {
  const { slug } = opts;

  const isToggleActive = useIsToggleActive();
  return (
    // TODO: Remove umich exception after CT availability is supported for that product
    slug !== UMICH_PACKAGE_SLUG &&
    (isToggleActive(COMMERCETOOLS_AVAILABILITY_FF) ||
      isToggleActive(COMMERCETOOLS_CFU_ACCESSORIES))
  );
};
