import React, { useEffect, useState } from 'react';
import useCart from '@content/client/www/cart/useCart';
import { couponErrorCodeToContentfulKey } from '@ecomm/cart-next/cart-summary/discounts/couponErrorCodes';
import {
  useCouponCodeError,
  useSetCouponCodeError,
} from '@ecomm/cart-next/context/CartContext';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import usePromoCodeAnalytics from '../../analytics/usePromoCodeAnalytics';
import { useAddPromoCodeMutation } from '../../graphql/mutations/AddPromoCode.generated';
import { useCartQuery } from '../../graphql/queries/Cart.generated';
import useCartContent from '../../shared/useCartContent';
import AddPromoCodeView from './AddPromoCodeView';

const AddPromoCode: React.FC<React.PropsWithChildren<unknown>> = () => {
  const isProjectPhoenixEnabled = useIsToggleActive()('projectPhoenix');
  const { data } = useCartQuery({
    variables: { calculateEstimatedShippingPrice: isProjectPhoenixEnabled },
    suspend: false,
  });

  const { trackAppliedPromoCode } = usePromoCodeAnalytics();

  const [isOpen, setIsOpen] = useState(false);
  const [promoCodeInput, setPromoCodeInput] = useState('');

  const [addCouponToCart, { data: mutationData, loading }] = useAddPromoCodeMutation();

  const content = useCart();
  const couponCodeError = useCouponCodeError();
  const setCouponCodeError = useSetCouponCodeError();

  useEffect(() => {
    if (couponCodeError) {
      setPromoCodeInput(couponCodeError.couponCode);
      setIsOpen(true);
    }
  }, [couponCodeError]);

  const promoCodeErrorCode =
    couponCodeError?.errorCode ??
    (mutationData?.addCouponToCart?.__typename === 'DomainError'
      ? mutationData.addCouponToCart.code
      : '');

  const promoCodeErrorKey = couponErrorCodeToContentfulKey(promoCodeErrorCode);
  const promoCodeErrorMessage = promoCodeErrorCode
    ? content.content?.[promoCodeErrorKey]?.value
    : undefined;

  const applyPromoCode = async () => {
    setCouponCodeError();
    const response = await addCouponToCart({
      variables: {
        couponCode: promoCodeInput,
        cartId: data?.cart?.id ?? '',
        calculateEstimatedShippingPrice: isProjectPhoenixEnabled,
      },
    });

    const hasErrorCode = response.data?.addCouponToCart?.__typename === 'DomainError';
    if (!hasErrorCode) {
      trackAppliedPromoCode(data?.cart, promoCodeInput);

      setPromoCodeInput('');
      setIsOpen(false);
    }
  };

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    setPromoCodeInput(e.currentTarget.value);
  };

  return (
    <AddPromoCodeView
      isOpen={isOpen}
      setIsOpenTrue={() => setIsOpen(true)}
      label={useCartContent('enterCode')}
      onSubmit={applyPromoCode}
      value={promoCodeInput}
      onChange={handleInputChange}
      isLoading={loading}
      promoCodeErrorMessage={promoCodeErrorMessage}
    />
  );
};

export default AddPromoCode;
