import { Support } from '@pelotoncycle/design-system';
import React from 'react';
import CartContent from '../../shared/CartContent';

type Props = {
  quantity: number;
};

const QuantityView: React.FC<React.PropsWithChildren<Props>> = ({ quantity }) => (
  <Support data-test-id="qty" size="medium" display="block">
    <CartContent field="quantity" values={{ quantity }} />
  </Support>
);

export default QuantityView;
