import React from 'react';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import useCartWithVariables from '../hooks/useCartWithVariables';
import MarginDivider from './MarginDivider';
import UpsellItem from './UpsellItem';

const Upsell: React.FC<React.PropsWithChildren<unknown>> = () => {
  const isUpsellEnabled = useIsToggleActive()('newCartPanelUpsellFeature');
  const cart = useCartWithVariables().data?.cart;

  if (!cart || !isUpsellEnabled) {
    return null;
  }

  const upsellType = getUpsellType(cart);

  return upsellType?.length > 0 ? (
    <>
      <MarginDivider />
      <UpsellItem upsellType={upsellType} cart={cart} />
    </>
  ) : null;
};

type CartHas = {
  hasTread: boolean;
  hasBike: boolean;
  hasBikePlus: boolean;
  hasGuide: boolean;
};

// Bike / Bike+ --> Cycling Shoes & Tread/Guide --> Dumbbells in that CFU Order
// TODO: Need a way to check for items already in cart which affects UpsellType
const getUpsellType = ({ hasTread, hasBike, hasBikePlus, hasGuide }: CartHas) => {
  switch (true) {
    case hasBike:
      return ['bikeupsell'];
    case hasBikePlus:
      return ['bike-plusupsell'];
    case hasTread:
      return ['treadupsell'];
    case hasGuide:
      return ['guideupsell'];
    default:
      return [];
  }
};

export default Upsell;
