import { grey, red } from '@pelotoncycle/design-system';

export const strikethroughTreatment = `
strong {
  color: ${red[50]};
}
/*
  - our strikethrough text from Contentful renders as a <del> element by default
  - strikethrough text coming from the PromoPrice renders as an <s> element
*/
del, s {
  color: ${grey[70]};

  /*
    - for strikethrough element a11y
    - we should consider making this a global style for all strikethrough elements
  */
  &::before,
  &::after {
    clip-path: inset(100%);
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
  &::before {
    content: ' [deletion start] ';
  }
  &::after {
    content: ' [deletion end] ';
  }
}
`;
