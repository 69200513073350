import { grey } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { EquipmentType } from '@ecomm/graphql/types.generated';
import useCartWithVariables from '../../hooks/useCartWithVariables';
import useGetCfuTypesInCart from '../../hooks/useGetCfuTypesInCart';
import AllAccessMembershipView from './AllAccessMembershipView';
import CartItemView from './CartItemView';
import GuideMembershipView from './GuideMembershipView';
import { getBundleItems, optionId, getSlug } from './models';
import RemovableCartItemView from './RemovableCartItemView';

type Props = {
  areItemsRemovable?: boolean;
};

const CartItems: React.FC<React.PropsWithChildren<Props>> = ({
  areItemsRemovable = true,
}) => {
  const { data } = useCartWithVariables();
  const cfuTypesInCart = useGetCfuTypesInCart();
  const hasOnlyGuide = cfuTypesInCart.every(cfuType => cfuType === EquipmentType.Guide);

  return (
    <>
      {data?.cart?.items.map(item => {
        const props = {
          name: item.name,
          itemQty: item.quantity,
          itemPrice: item.priceInCents.amount,
          bundleItems: getBundleItems(item),
          key: item.id,
          kind: item.__typename,
          description: item.description,
          optionId: optionId(item),
          slug: getSlug(item),
        };

        return areItemsRemovable ? (
          <StyledRemovableCartItemView {...props} />
        ) : (
          <CartItemView {...props} />
        );
      })}
      {hasOnlyGuide ? <GuideMembershipView /> : <AllAccessMembershipView />}
    </>
  );
};

const StyledRemovableCartItemView = styled(RemovableCartItemView)`
  &:first-of-type {
    border-top: none;
  }

  &:last-of-type {
    border-bottom: 1px solid ${grey[50]};
  }
`;

export default CartItems;
