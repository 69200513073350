import { useCallback } from 'react';
import { useTracking } from 'react-tracking';
import { toSegmentProducts, toSlugs } from '@ecomm/cart-next/analytics/segmentMappings';
import { isBundleItem } from '@ecomm/cart-next/cart-summary/cart-items/models';
import useCartWithVariables from '@ecomm/cart-next/hooks/useCartWithVariables';
import useGetTrackingCategories from '@ecomm/cart-next/hooks/useGetTrackingCategories';

const useTrackGiftCheckbox = () => {
  const cart = useCartWithVariables().data?.cart;
  const { trackEvent } = useTracking();
  const categories = useGetTrackingCategories();
  const cartItems = cart?.items ?? [];
  const track = useCallback(
    (isGift: boolean, position: 'checkout' | 'cart') => {
      trackEvent({
        event: 'Gift Checkbox Selected',
        properties: {
          cartId: cart?.id,
          category: categories,
          hasTradeIn: (cart?.numberOfTradeIns ?? 0) > 0,
          products: toSegmentProducts(cartItems),
          hasAccessory: cart?.hasAccessories,
          hasGuide: cart?.hasGuide,
          hasAccessoryBundle: cartItems.some(i => i.__typename == 'AccessoryBundleItem'),
          accessoryName: String(
            cartItems.filter(i => i.__typename == 'ProductItem').map(i => i.name),
          ),
          bundleName: cartItems.filter(i => isBundleItem(i)).map(i => i.name),
          hasCPO: cart?.hasRefurbBike || cart?.hasRefurbBikePlus,
          hasOPC: cart?.hasEquipmentLease,
          selected: isGift,
          slug: toSlugs(cart),
          position,
        },
      });
    },
    [trackEvent, cart],
  );
  return track;
};
export default useTrackGiftCheckbox;
