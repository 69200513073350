import React from 'react';
import { OTD_COLOR_BIKE_ESTIMATES } from '@content/client/www/otd/mapToView';
import useGetCfuTypesInCart from '@ecomm/cart-next/hooks/useGetCfuTypesInCart';
import type * as Types from '@ecomm/graphql/types.generated';
import { useOtdEstimate } from '@ecomm/otd/useOtdEstimates';
import useCartContainsColoredBike from '@ecomm/pg-checkout/hooks/useCartContainsColoredBike';
import { usePostalCodeContext } from '@ecomm/postal-code/PostalCodeContext';
import MarginDivider from '../MarginDivider';
import DynamicOtdEstimate from './DynamicOtdEstimate';

const OtdEstimate: React.FC<React.PropsWithChildren<unknown>> = () => {
  const cfuTypes = useGetCfuTypesInCart();

  if (cfuTypes.length >= 1) {
    return <OtdContainer cfuTypes={cfuTypes} />;
  } else {
    return null;
  }
};

const OtdContainer: React.FC<
  React.PropsWithChildren<{ cfuTypes: Types.EquipmentType[] }>
> = ({ cfuTypes }) => {
  const [initialCfu] = cfuTypes;
  const { postalCode } = usePostalCodeContext();
  const countryEstimate = useOtdEstimate(initialCfu);
  const cartContainsColoredBike = useCartContainsColoredBike();
  const otdKey = cartContainsColoredBike ? OTD_COLOR_BIKE_ESTIMATES : undefined;

  return (
    <Container>
      <DynamicOtdEstimate
        cfuType={initialCfu}
        postalCode={postalCode}
        countryEstimate={countryEstimate}
        otdKey={otdKey}
      />
    </Container>
  );
};

export default OtdEstimate;

const Container: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <section>
    <MarginDivider />
    {children}
  </section>
);
