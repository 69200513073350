import { useLocale } from '@peloton/internationalize';
import { Locale } from '@peloton/internationalize/models/locale';
import { useCommercetoolsClient } from '../apollo';
import type { UseQueryResult } from '../models/main';
import type {
  GetCategoryByKeyQuery,
  GetCategoryByKeyQueryHookResult,
} from '../queries/GetCategoryByKey.generated';
import { useGetCategoryByKeyQuery } from '../queries/GetCategoryByKey.generated';
import { sortByOrderHint } from '../utils/sortByOrderHint';

export type UseGetCategoryByKey = UseQueryResult<GetCategoryByKeyQuery['category']>;

export const useGetCategoryByKey = (categoryKey: string): UseGetCategoryByKey => {
  const result: UseGetCategoryByKey = { loading: true };
  const locale = useLocale();
  const acceptLanguage = [locale, Locale.Default];
  const client = useCommercetoolsClient();

  const query: GetCategoryByKeyQueryHookResult = useGetCategoryByKeyQuery({
    fetchPolicy: 'cache-first',
    variables: {
      acceptLanguage,
      key: categoryKey,
    },
    client,
    notifyOnNetworkStatusChange: true,
  });

  const response = query.data?.category;

  if (query.loading === false) {
    result.loading = false;

    if (query.error) {
      result.error = query.error;
    }

    if (response) {
      const subCategories = response.children;

      result.data = {
        ...response,
        ...(subCategories && { children: sortByOrderHint(subCategories) }),
      };
    }
  }

  return result;
};
