import { brand, Container } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import CartPanelOverlay from './CartPanelOverlay';

type Props = {
  isDisabled: boolean;
  className?: string;
};

const CartContainer: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  isDisabled,
  className,
}) => (
  <StyledContainer
    isDisabled={isDisabled}
    className={className}
    position="relative"
    backgroundColor={brand.light}
    style={{ overflow: 'visible', minHeight: '100vh' }}
    data-test-id="cartContainer"
  >
    {children}
    {isDisabled && <CartPanelOverlay />}
  </StyledContainer>
);

const StyledContainer = styled(Container).attrs(props => ({ ...props }))<{
  isDisabled: boolean;
}>`
  ${({ isDisabled }) => (isDisabled ? 'pointer-events: none;' : '')}
`;

export default CartContainer;
