import { grey, spacing, Support } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { sortProducts } from '../../../shop/sortProducts';

type Props = {
  bundleItems: any[];
  className?: string;
};

const ItemsInBundleView: React.FC<React.PropsWithChildren<Props>> = ({
  bundleItems,
  className,
}) =>
  bundleItems.length > 0 ? (
    <StyledBundleItems>
      {sortProducts(bundleItems).map(({ name, id, quantity }) => (
        <StyledSubItems
          className={className}
          data-test-id="bundle-item"
          size="small"
          key={id}
        >
          {name}
          {quantity > 1 && ' (' + quantity + ')'}
        </StyledSubItems>
      ))}
    </StyledBundleItems>
  ) : null;

const StyledBundleItems = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${spacing[4]};
`;

const StyledSubItems = styled(Support)`
  margin-left: 0px;
  color: ${grey[70]};
  max-width: 256px;
`;

export default ItemsInBundleView;
