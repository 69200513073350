import { grey } from '@pelotoncycle/design-system';
import { transparentize } from 'polished';
import styled from 'styled-components';

const MarginDivider = styled.hr`
  border: none;
  background-color: ${transparentize(0.7, grey[50])};
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export default MarginDivider;
