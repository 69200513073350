import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import useCartWithVariables from './useCartWithVariables';

const useIsCartEligibleForGifting = (): boolean => {
  const isToggleActive = useIsToggleActive();
  const cart = useCartWithVariables().data?.cart;

  const isGiftingOrdersActive = isToggleActive('giftingOrders');
  const hasEquipment = cart?.hasEquipment;
  const hasEquipmentLease = cart?.hasEquipmentLease;

  if (!isGiftingOrdersActive) {
    return false;
  }

  return Boolean(hasEquipment && !hasEquipmentLease);
};

export default useIsCartEligibleForGifting;
