import { FlexChild, white } from '@pelotoncycle/design-system';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { PelotonText } from '@peloton/logos';
import { useLocale } from '@peloton/next/hooks/useLocale';
import { media } from '@peloton/styles';
import useNav from '@content/client/www/nav/useNav';
import { Link } from '@ecomm/internationalize-ui';

const MOBILE_WIDTH = 80;
const DESKTOP_WIDTH = 116;
const LOGO_TRACKING_ID = 'nav.peloton';

export const GenericLogo: React.FC<React.PropsWithChildren<{ label: string }>> = ({
  label,
}) => {
  const { trackEvent } = useTracking();
  const onClick = () => {
    trackEvent({
      properties: {
        category: LOGO_TRACKING_ID,
        parent: LOGO_TRACKING_ID,
        linkTo: '/',
        linkName: LOGO_TRACKING_ID,
      },
    });
  };

  return (
    <FlexChild flex="1">
      <StyledLogoLink to="/" href="/" ariaLabel={label} onClick={onClick}>
        <StyledPelotonTextLogo data-test-id="pelotonLogo" fill={white} />
      </StyledLogoLink>
    </FlexChild>
  );
};

const EcommLogo: React.FC<React.PropsWithChildren<unknown>> = () => {
  const locale = useLocale();
  const { content, isLoading } = useNav(locale);

  if (isLoading) {
    return null;
  }

  return <GenericLogo label={content.logoAriaLabel.value} />;
};

export default EcommLogo;

const StyledLogoLink = styled(Link)`
  display: flex;
  height: 100%;

  span {
    display: flex;
  }
`;

const StyledPelotonTextLogo = styled(PelotonText)`
  width: ${MOBILE_WIDTH}px;
  height: auto;
  overflow: visible;

  ${media.desktopXLarge`
    width: ${DESKTOP_WIDTH}px;
  `}
`;
