import React from 'react';
import { ScreenReaderText } from '@peloton/accessibility';
import { useHasItemJustBeenAdded } from '../context/CartContext';
import CartContent from '../shared/CartContent';

const ScreenReaderNewItemAlert: React.FC<React.PropsWithChildren<unknown>> = () => {
  const hasItemJustBeenAdded = useHasItemJustBeenAdded();

  return hasItemJustBeenAdded ? (
    <ScreenReaderText role="alert">
      <CartContent field="screenReaderNewItemAlert" />
    </ScreenReaderText>
  ) : null;
};

export default ScreenReaderNewItemAlert;
