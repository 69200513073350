import { Support, slate, Container } from '@pelotoncycle/design-system';
import React from 'react';
import { nbsp } from '@peloton/text';
import useCartWithVariables from '../hooks/useCartWithVariables';
import CartContent from '../shared/CartContent';
import PriceWithCents from '../shared/PriceWithCents';

const Vat: React.FC<React.PropsWithChildren<unknown>> = () => {
  const tax = useCartWithVariables().data?.cart?.tax;

  return tax && tax.isVat ? (
    <Container>
      <Support size="medium" textColor={slate[50]}>
        <CartContent field="vatLabel" />
      </Support>
      {nbsp}
      <Support size="medium" textColor={slate[50]}>
        {tax.price.amount !== 0 ? (
          <PriceWithCents amount={tax.price.amount} />
        ) : (
          <CartContent field="noAmount" />
        )}
      </Support>
    </Container>
  ) : null;
};

export default Vat;
