import { useApolloClient } from '@apollo/client';
import { useApq } from '@peloton/auth/config';
import { toLocaleFromTLD } from '@peloton/internationalize/models/locale';
import { toStoreSlug } from '@peloton/stores';
import { fromLocale, toCurrencyfromLocale } from '@ecomm/graphql-bridge';
import type { ProductSelection } from '@ecomm/graphql/types.generated';
import type {
  VariantsBySelectionsQueryVariables,
  VariantsBySelectionsQuery,
} from '@ecomm/shop/graphql/VariantsBySelections.generated';
import { VariantsBySelectionsDocument } from '@ecomm/shop/graphql/VariantsBySelections.generated';

export type Variants = VariantsBySelectionsQuery['catalog']['variantsBySelections'];

const useDynamicVariantsBySelectionsQuery = () => {
  const apolloClient = useApolloClient();
  const baseLocale = toLocaleFromTLD();
  const locale = fromLocale(baseLocale);
  const currency = toCurrencyfromLocale(baseLocale);
  const catalog = toStoreSlug();
  const apq = useApq();
  return async (productSelections: ProductSelection[]): Promise<Variants> => {
    const { data } = await apolloClient.query<
      VariantsBySelectionsQuery,
      VariantsBySelectionsQueryVariables
    >({
      query: VariantsBySelectionsDocument,
      variables: {
        slug: catalog,
        locale,
        currency,
        productSelections,
      },
      context: { useApq: apq },
    });

    return data?.catalog?.variantsBySelections;
  };
};

export default useDynamicVariantsBySelectionsQuery;
