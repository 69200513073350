import { Flex, Label } from '@pelotoncycle/design-system';
import React from 'react';
import useCartWithVariables from '../hooks/useCartWithVariables';
import CartContent from '../shared/CartContent';
import PriceWithCents from '../shared/PriceWithCents';

const Subtotal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { data } = useCartWithVariables();

  return (
    <Flex justifyContent="space-between">
      <Label size="large" as="span">
        <CartContent
          field="subtotalLabel"
          values={{ quantity: data?.cart?.numberOfItems ?? 0 }}
        />
      </Label>
      <Label size="large" as="span">
        <PriceWithCents amount={data?.cart?.subtotal.amount ?? 0} />
      </Label>
    </Flex>
  );
};

export default Subtotal;
