import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import { Partners } from '@ecomm/financing/models/Partners';
import { usePrograms } from '@ecomm/programs/Context';
import useBundleFinancing from '@ecomm/shop/hooks/useBundleFinancing';
import { colorBikes } from '@page-builder/modules/Overview/constants';
import useCartWithVariables from './useCartWithVariables';

interface CartItem {
  slug?: string;
}

// TODO: temp until we can configure financing availability logic at the product sku level
export const UMICH_PACKAGE_SLUG = 'bike-plus-umich-package-us';
export const UMICH_RENTAL_PACKAGE_SLUG = ['faas-pkg-umich', 'faas-pkg-umich-bp'];

const FINANCING_UNAVAILABLE_SLUGS = [...Object.keys(colorBikes)];

function hasSlug(item: any): item is CartItem {
  return item && typeof item.slug === 'string';
}

const useIsCartEligibleForFinancing = (): boolean => {
  const { financingPartner } = usePrograms();
  const hasFinancingPartner = financingPartner !== Partners.None;
  const cart = useCartWithVariables().data?.cart;
  const hasEquipment = cart?.hasEquipment;
  const hasEquipmentLease = cart?.hasEquipmentLease;
  const { financingState } = useBundleFinancing();
  const hasRow = cart?.hasRow && financingState?.row;
  const hasTread = cart?.hasTread && financingState?.tread;
  const hasGuide = cart?.hasGuide && financingState?.guide;
  const hasRefurb = cart?.hasRefurbBike && financingState?.['refurbished-bike'];
  const hasRefurbPlus =
    cart?.hasRefurbBikePlus && financingState?.['refurbished-bike-plus'];
  const hasBike = cart?.hasBike && financingState?.bike;
  const hasBikePlus = cart?.hasBikePlus && financingState?.['bike-plus'];
  const hasTreadPlus = cart?.hasTreadPlus && financingState?.['tread-plus'];
  const financingUnavailableSlugs = [...FINANCING_UNAVAILABLE_SLUGS];
  const isUmichAvailableForFinancing = useIsToggleActive()('umichFinancing');
  const isUmichRentalAvailableForFinancing = useIsToggleActive()('showUMichRentalPDP');
  if (!isUmichAvailableForFinancing) {
    financingUnavailableSlugs.push(UMICH_PACKAGE_SLUG);
  }
  if (isUmichRentalAvailableForFinancing) {
    financingUnavailableSlugs.push(...UMICH_RENTAL_PACKAGE_SLUG);
  }
  const cartItems = cart?.items || [];

  const cartItemsMakeFinancingUnavailable = cartItems.some(
    item => hasSlug(item) && financingUnavailableSlugs.indexOf(item?.slug) > -1,
  );

  const isAnyBundleInCartFinancingAvailible =
    hasRow ||
    hasTread ||
    hasGuide ||
    hasRefurb ||
    hasRefurbPlus ||
    hasBike ||
    hasBikePlus ||
    hasTreadPlus;

  if (cartItemsMakeFinancingUnavailable) return false;

  return Boolean(
    hasFinancingPartner &&
      hasEquipment &&
      !hasEquipmentLease &&
      isAnyBundleInCartFinancingAvailible,
  );
};

export default useIsCartEligibleForFinancing;
