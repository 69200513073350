import type { Container } from '@pelotoncycle/design-system';
import React from 'react';
import type { PropsOf } from '@peloton/react';
import { usePrograms } from '@ecomm/programs/Context';
import useIsCartEligibleForFinancing from '../../hooks/useIsCartEligibleForFinancing';
import PartnerFinancing from './PartnerFinancing';

type Props = {
  verticalMargin?: PropsOf<typeof Container>['verticalMargin'];
};

const Financing: React.FC<React.PropsWithChildren<Props>> = ({ verticalMargin }) => {
  const { financingPartner, secondaryFinancingPartner } = usePrograms();
  const isCartEligibleForFinancing = useIsCartEligibleForFinancing();

  return isCartEligibleForFinancing ? (
    <PartnerFinancing
      financingPartner={financingPartner}
      secondaryFinancingPartner={secondaryFinancingPartner}
      verticalMargin={verticalMargin}
    />
  ) : null;
};

export default Financing;
