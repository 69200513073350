import { Container, spacing, grey } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import useGetCartItems from '@ecomm/commercetools/hooks/useGetCartItems';
import { useShowHaulaway } from '@ecomm/feature-toggle/Haulaway';
import isVatCurrency from '@ecomm/pg-checkout-commercetools/helpers/isVatCurrency';

import {
  getCartTax,
  getCartSubTotal,
  getCartShipping,
  getCartTotal,
  isUnavailableProductExists,
  isUnavailableProductExistsInBundles,
  hasCFU,
  hasCFUExceptGuide,
  isCartEligibleForHaulaway,
} from '../../helpers/ct-cart-helper';

import { useMigrationStatus } from '../../utils/useMigrationStatus';
import PromoCodeAppliedSection from '../checkout-section-components/promo-code-applied/PromoCodeAppliedSection';
import Financing from '../financing/Financing';
import AddPromoCode from './AddPromoCode';
import CartItems from './CartItems';
import type { RemoveItemArgs } from './CartSummary';
import Gifting from './Gifting';
import HaulawayCopy from './HaulawayCopy';
import Otd from './Otd';
import CheckoutButton from './shared/CheckoutButton';
import Shipping from './Shipping';
import Subtotal from './Subtotal';
import Tax from './Tax';
import Total from './Total';
import Vat from './Vat';

type Props = {
  className?: string;
  cartLoading: boolean;
  handleCheckout: () => void;
  removeItem: ({ id, isBundle, isBundleCFU }: RemoveItemArgs) => void;
};

const CartSummaryView: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  cartLoading,
  handleCheckout,
  removeItem,
}) => {
  const { data: cartItems } = useGetCartItems();
  const { buyFlowATCEnabled, buyFlowCheckoutEnabled } = useMigrationStatus();

  const isVat = isVatCurrency(
    cartItems?.priceSummaries?.cartPriceSummary?.total?.currencyCode,
  );

  const numberOfCartItems = cartItems?.totalLineItemQuantity ?? 0;
  const tax = getCartTax(cartItems);
  const lineItems = cartItems?.lineItems ?? [];
  const subTotal = getCartSubTotal(cartItems);
  const total = getCartTotal(cartItems);
  const unavailableProductExists = isUnavailableProductExists(lineItems);
  const unavailableProductExistsInBundles = isUnavailableProductExistsInBundles(
    lineItems,
  );
  const cartHasCFU = hasCFU(lineItems);
  const cartHasCFUExceptGuide = hasCFUExceptGuide(lineItems);
  const shippingAddress = cartItems?.shippingAddress ?? null;
  const shipping = getCartShipping(cartItems);
  const isHaulawayActive = useShowHaulaway();
  const isCartEligible = isCartEligibleForHaulaway(cartItems?.lineItems ?? []);
  const showHaulawayCopy = isHaulawayActive && isCartEligible;
  return (
    <>
      <Container
        className={className}
        data-test-id="orderSummary"
        padding={{
          mobile: `0 ${spacing[16]} ${spacing[32]}`,
          tablet: `0 ${spacing[24]} ${spacing[32]}`,
          desktop: `0 ${spacing[24]} ${spacing[32]}`,
        }}
      >
        <CartItems removeItem={removeItem} />
        <MarginDivider />
        <Subtotal subtotal={subTotal} numberOfItems={numberOfCartItems} />
        <Shipping cartHasCFUExceptGuide={cartHasCFUExceptGuide} shipping={shipping} />
        <PromoCodeAppliedSection allowRemove={true} />
        {!isVat && <Tax tax={tax} />}
        {buyFlowATCEnabled && <AddPromoCode />}
        <MarginDivider />
        <Total total={total} shippingAddress={shippingAddress} />
        {isVat && <Vat />}
        {buyFlowCheckoutEnabled && <StyledFinancing />}
        {cartHasCFU && (
          <>
            <MarginDivider />
            <Otd />
          </>
        )}
        <Gifting />
        {showHaulawayCopy && <HaulawayCopy />}
      </Container>
      <CheckoutButton
        handleClick={handleCheckout}
        isDisabled={
          unavailableProductExists || unavailableProductExistsInBundles || cartLoading
        }
      />
    </>
  );
};

const MarginDivider = styled.hr`
  border: none;
  background-color: ${grey[40]};
  height: 1px;
  margin: ${spacing[24]} 0;
  &:first-of-type {
    margin-top: 0;
  }
`;

const StyledFinancing = styled(Financing)`
  margin-top: ${spacing[16]};
`;

export default CartSummaryView;
