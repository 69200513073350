enum ErrorCode {
  PromotionAllowedOncePerTransaction = 'couponErrorPromotionAllowedOncePerTransaction',
  CartDoesNotQualifyForPromotion = 'couponErrorCartDoesNotQualifyForPromotion',
  PromotionNotApplicable = 'couponErrorPromotionNotApplicable',
  PromotionRedemptionLimitReached = 'couponErrorPromotionRedemptionLimitReached',
  InvalidCode = 'couponErrorInvalidCode',
  PromotionExpired = 'couponErrorPromotionExpired',
  RegionInvalid = 'couponErrorRegionInvalid',
}

const apiErrorMap = {
  4922: ErrorCode.CartDoesNotQualifyForPromotion,
  4924: ErrorCode.PromotionAllowedOncePerTransaction,
  4925: ErrorCode.PromotionNotApplicable,
  4928: ErrorCode.CartDoesNotQualifyForPromotion,
  4929: ErrorCode.CartDoesNotQualifyForPromotion,
  4930: ErrorCode.PromotionAllowedOncePerTransaction,
  4931: ErrorCode.PromotionRedemptionLimitReached,
  4932: ErrorCode.InvalidCode,
  4933: ErrorCode.PromotionExpired,
  4935: ErrorCode.RegionInvalid,
};

export const couponErrorCodeToContentfulKey = (code: string) =>
  apiErrorMap[code] ?? ErrorCode.InvalidCode;
