import {
  Label,
  grey,
  spacing,
  white,
  pixelToRem,
  Icon,
} from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import useCartContent from '@content/client/www/cart/useCart';

const HaulawayCopy = () => {
  const { content: cartContent } = useCartContent();
  return (
    <Container>
      <LabelSection>
        <Label size="large" textColor={grey[90]} data-test-id="haulaway-info">
          {cartContent?.haulawayLabel?.value}
        </Label>
        <StyledIcon name="equipmentRemoval" height={16} primaryColor={grey[70]} />
      </LabelSection>
      <Label size="large" textColor={grey[70]} data-test-id="haulaway-description">
        {cartContent?.haulawayDescription?.value}
      </Label>
    </Container>
  );
};

const LabelSection = styled.div`
  display: flex;
  align-items: center;
`;

const Container = styled.div`
margin-top: ${spacing[8]};
background-color: ${white};
padding: ${pixelToRem(14)}};
`;

const StyledIcon = styled(Icon)`
  margin-left: ${spacing[8]};
`;

export default HaulawayCopy;
