import React from 'react';
import FormatText from '@ecomm/copy/FormatText';
import type { Field } from './useGiftingContent';
import useGiftingContent from './useGiftingContent';

type Props = {
  field: Field;
  values?: Record<string, string | number | boolean>;
};

const GiftingContent: React.FC<React.PropsWithChildren<Props>> = ({ field, values }) => (
  <FormatText content={useGiftingContent(field)} values={values} />
);

export default GiftingContent;
