import { red, Icon } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';

const CartPanelOverlay = () => (
  <>
    <Overlay data-test-id="cart-container-overlay" />
    <StyledSpinner />
  </>
);
const StyledSpinner = styled(Icon).attrs({
  height: 30,
  primaryColor: red[80],
  name: 'spinner',
  'data-test-id': 'cart-panel-loading-spinner',
})`
  position: absolute;
  top: 50%;
  left: 50%;
`;
const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  background-color: rgba(255, 255, 255, 0.6);
`;
export default CartPanelOverlay;
