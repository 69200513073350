import { Container, grey } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { toLocale } from '@peloton/internationalize/models/locale';
import type { PropsOf } from '@peloton/react';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import { roundByFinancingPartner } from '@ecomm/financing/models/monthlyPaymentWithApr';
import type { Partners } from '@ecomm/financing/models/Partners';
import {
  financingTermFromCart,
  financingBundleTypeFromCart,
} from '@ecomm/financing/models/term';
import { useApr } from '@ecomm/pricing/helpers/useApr';
import useCartWithVariables from '../../hooks/useCartWithVariables';
import FinancingSupportContainer from './FinancingSupportContainer';

type Props = {
  financingPartner: Partners;
  secondaryFinancingPartner: Partners;
  verticalMargin?: PropsOf<typeof Container>['verticalMargin'];
};

const PartnerFinancing: React.FC<React.PropsWithChildren<Props>> = ({
  financingPartner,
  secondaryFinancingPartner,
  verticalMargin,
}) => {
  const cart = useCartWithVariables().data?.cart;
  const locale = toLocale();
  const isZipThirtySixMonthsFinancingEnabled = useIsToggleActive()(
    'zipThirtySixMonthFinancing',
  );

  const hasBikeInCart = cart?.hasBike ?? false;
  const hasTreadInCart = cart?.hasTread ?? false;
  const hasBikePlusInCart = cart?.hasBikePlus ?? false;
  const hasGuideInCart = cart?.hasGuide ?? false;
  const hasRowInCart = cart?.hasRow ?? false;
  const hasTreadPlusInCart = cart?.hasTreadPlus ?? false;

  const term = financingTermFromCart(
    {
      partner: financingPartner,
      hasBikeInCart,
      hasTreadInCart,
      hasBikePlusInCart,
      hasGuideInCart,
      hasRowInCart,
      hasTreadPlusInCart,
    },
    isZipThirtySixMonthsFinancingEnabled,
  );
  const bundleType = financingBundleTypeFromCart({
    hasBikeInCart,
    hasTreadInCart,
    hasBikePlusInCart,
    hasGuideInCart,
    hasRowInCart,
    hasTreadPlusInCart,
  });
  const apr = useApr(locale, bundleType, term);
  const total = cart?.total.amount ?? 0;
  const monthlyTotal = roundByFinancingPartner(total, term, apr, financingPartner);

  return (
    <StyledContainer verticalMargin={verticalMargin}>
      <FinancingSupportContainer
        monthlyTotal={monthlyTotal}
        term={term}
        total={total}
        financingPartner={financingPartner}
        secondaryFinancingPartner={secondaryFinancingPartner}
        bundleType={bundleType}
      />
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)`
  .affirm-modal-trigger {
    &:hover {
      color: ${grey[70]};
    }
  }
`;

export default PartnerFinancing;
