import { Flex, grey, spacing, Icon, Checkbox } from '@pelotoncycle/design-system';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { ContentSizedDiv } from '@peloton/content-sized-div/ContentSizedDiv';
import { defaultTransition } from '@peloton/styles';
import { useSetIsCartPanelDisabled } from '@ecomm/cart-next/context/CartContext';
import { useSetIsGiftMutation } from '@ecomm/checkout/hooks/setIsGiftOnCart.generated';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import { b4 } from '@ecomm/typography';
import useTrackGiftCheckbox from '../../pg-checkout/analytics/useTrackGiftCheckbox';
import useCartWithVariables from '../hooks/useCartWithVariables';
import useIsCartEligibleForGifting from '../hooks/useIsCartEligibleForGifting';
import CartContent from '../shared/CartContent';
import CartMarkdownContent from '../shared/CartMarkdownContent';
import infoMarkdownProps from '../shared/InfoMarkdownProps';
import AddAllAccessMembership from './AddAllAccessMembership';

const GiftCheckbox: React.FC<React.PropsWithChildren<unknown>> = () => {
  const isToggleActive = useIsToggleActive();
  const { data } = useCartWithVariables();
  const isGift = data?.cart?.isGift ?? false;
  const [setIsGiftMutation] = useSetIsGiftMutation();
  const [localIsGift, setLocalIsGift] = useState(isGift);
  const isCartEligibleForGifting = useIsCartEligibleForGifting();
  const isGiftingEnhancementEnabled = useIsToggleActive()('giftingCheckoutWithoutAam');
  const trackGiftCheckbox = useTrackGiftCheckbox();
  const setIsCartDisabled = useSetIsCartPanelDisabled();
  const myRef = useRef<null | HTMLDivElement>(null);
  useEffect(() => {
    setLocalIsGift(isGift);
  }, [isGift]);

  const isProjectPhoenixEnabled = isToggleActive('projectPhoenix');

  const setIsGift = useCallback(
    debounce(async g => {
      trackGiftCheckbox(g, 'cart');
      setIsCartDisabled(true);

      await setIsGiftMutation({
        variables: {
          cartId: data?.cart?.id ?? '',
          isGift: g,
          calculateEstimatedShippingPrice: isProjectPhoenixEnabled,
        },
        optimisticResponse: {
          setIsGiftOnCart: {
            id: data?.cart?.id ?? '',
            __typename: 'CartNext',
            isGift: g,
          } as any,
        },
      });

      setIsCartDisabled(false);
      myRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 500),
    [],
  );

  if (!isGift && !isCartEligibleForGifting) {
    return null;
  }

  const handleClick = () => {
    setLocalIsGift(!isGift);
    setIsGift(!isGift);
  };

  return isGiftingEnhancementEnabled ? (
    <>
      <StyledContainer>
        <Flex>
          <StyledCheckbox
            labelText={<CartContent field="giftingLabel" />}
            checked={localIsGift}
            inputAlignment="center"
            handleChange={handleClick}
            name="gift-checkbox"
          />
          <StyledIcon name="gifting" height={16} primaryColor={grey[70]} />
        </Flex>
        <HeightTransition>
          <HeightWrapper isOpen={localIsGift}>
            <Description>
              <CartMarkdownContent
                field="giftingDescription"
                renderers={infoMarkdownProps}
              />
            </Description>
          </HeightWrapper>
        </HeightTransition>
      </StyledContainer>

      {localIsGift && <AddAllAccessMembership ref={myRef} />}
    </>
  ) : null;
};

export default GiftCheckbox;

const StyledContainer = styled.div`
  margin-top: 24px;
  background-color: white;
  border-radius: 4px;
  padding: 19px 40px 19px 20px;
`;

const HeightTransition = styled(ContentSizedDiv)`
  ${defaultTransition()}
`;

const HeightWrapper = styled.div`
  ${(props: { isOpen: boolean }) => (props.isOpen ? '' : 'height: 0;')}
`;

const Description = styled.div`
  padding-top: 7px;
  padding-left: 27px;
  ${b4}
`;

const StyledCheckbox = styled(Checkbox)`
  width: 150px;
`;

const StyledIcon = styled(Icon)`
  margin-left: ${spacing[8]};
  margin-bottom: ${spacing[4]};
  align-self: center;
`;
