import { Flex, Label, black } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import CartContent from '@ecomm/cart-next/shared/CartContent';
import FormatText from '@ecomm/copy/FormatText';
import { toDollars } from '@ecomm/models';

const StyledCaseLabel = styled(Label)`
  text-transform: lowercase;
  ::first-letter {
    text-transform: capitalize;
  }
`;

type Props = {
  cartHasCFUExceptGuide: boolean;
  shipping: number;
};

const Shipping: React.FC<React.PropsWithChildren<Props>> = ({
  cartHasCFUExceptGuide,
  shipping,
}) => {
  const hasShipping = shipping > 0;
  return (
    <Flex justifyContent="space-between">
      {/* <StyledCaseLabel size="large" data-test-id="shipping-label" textColor={black}>
        {cartContent?.estimatedShippingLabel?.value}
      </StyledCaseLabel>
      <Label size="large" as="span" data-test-id="shipping-included" textColor={black}>
        {cartContent?.shippingIncluded?.value}
      </Label> */}
      <StyledCaseLabel size="large" data-test-id="shipping-label" textColor={black}>
        <CartContent
          field={cartHasCFUExceptGuide ? 'estimatedShippingLabel' : 'shippingLabel'}
        />
      </StyledCaseLabel>
      <Label size="large" as="span" data-test-id="shipping-included" textColor={black}>
        {hasShipping && !cartHasCFUExceptGuide ? (
          <FormatText
            content="{price, number, currencyWithCents}"
            values={{ price: toDollars(shipping) }}
          />
        ) : (
          <CartContent field={cartHasCFUExceptGuide ? 'shippingIncluded' : 'noAmount'} />
        )}
      </Label>
    </Flex>
  );
};

export default Shipping;
