import React from 'react';
import { useIsCartPanelDisabled, useIsCartLoading } from '../context/CartContext';
import CartContainer from './CartContainer';
import CartSummaryView from './CartSummaryView';
import EmptyCartView from './EmptyCartView';
import LoadingCartView from './LoadingCartView';

type Props = {
  numberOfItems?: number;
  className?: string;
  isCheckout?: boolean;
};

const CartSummary: React.FC<React.PropsWithChildren<Props>> = ({
  numberOfItems,
  isCheckout,
  className,
}) => {
  const isCartEmpty = !numberOfItems || numberOfItems === 0;
  const isDisabled = useIsCartPanelDisabled();
  const isLoading = useIsCartLoading();
  return (
    <CartContainer className={className} isDisabled={isDisabled}>
      {isLoading ? (
        <LoadingCartView />
      ) : isCartEmpty ? (
        <EmptyCartView />
      ) : (
        <CartSummaryView isCheckout={isCheckout} />
      )}
    </CartContainer>
  );
};

export default CartSummary;
