import React from 'react';
import FormatText from '@ecomm/copy/FormatText';
import type { Money } from '@ecomm/models';
import { toDollars } from '@ecomm/models';

type Props = {
  amount: Money;
};

const PriceWithCents: React.FC<React.PropsWithChildren<Props>> = ({ amount }) => (
  <FormatText
    content="{price, number, currencyWithCents}"
    values={{ price: toDollars(amount) }}
  />
);

export default PriceWithCents;
