import { Label } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { useLocale } from '@peloton/internationalize';
import useCartContent from '@ecomm/cart-next/shared/useCartContent';
import type { Money } from '@ecomm/models';
import { toDollars } from '@ecomm/models';
import {
  useRentalPrices,
  isUMichBikesOPC,
  UMICH_BIKES_OPC_DISCOUNT,
} from '@ecomm/rentals/models';
import { strikethroughTreatment } from '@page-builder/modules/Overview/ShopDrawers/priceUtils';
import Markdown from '@page-builder/utils/Markdown';
import useCartWithVariables from '../../hooks/useCartWithVariables';
import CartContent from '../../shared/CartContent';

type Props = {
  priceInCents: Money;
  className?: string;
  slug?: string;
};

const LeasingBundlePrice: React.FC<React.PropsWithChildren<Props>> = ({
  priceInCents,
  className,
  slug,
}) => {
  const UMichContent = useCartContent('leaseUMichPricing');
  const hasBikePlusLease = useCartWithVariables().data?.cart?.hasBikePlusLease;
  const locale = useLocale();
  const BIKE_RENTAL_PRICE = useRentalPrices('BIKE', locale);
  const BIKE_PLUS_RENTAL_PRICE = useRentalPrices('BIKE_PLUS', locale);
  const isUMichOPC = isUMichBikesOPC(slug);
  const monthlyPrice = hasBikePlusLease
    ? BIKE_PLUS_RENTAL_PRICE.monthly
    : BIKE_RENTAL_PRICE.monthly;

  return (
    <StyledLabel size="large" className={className}>
      {isUMichOPC ? (
        <Markdown
          content={UMichContent}
          values={{
            setupPrice: toDollars(priceInCents),
            discountPrice: monthlyPrice - UMICH_BIKES_OPC_DISCOUNT,
            monthlyPrice,
          }}
        />
      ) : (
        <CartContent
          field="leasePricing"
          values={{
            setupPrice: toDollars(priceInCents),
            monthlyPrice,
          }}
        />
      )}
    </StyledLabel>
  );
};

const StyledLabel = styled(Label)`
  ${strikethroughTreatment}
`;

export default LeasingBundlePrice;
