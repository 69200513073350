import toFormattedText from '@peloton/copy/toFormattedText';
import type { ProductVariant } from '@ecomm/graphql/types.generated';
import useCatalogQueryOptions from '@ecomm/graphql/useCatalogQueryOptions';
import { toDollars } from '@ecomm/models';
import type { Selection } from '@ecomm/shop/models/Selections';
import { useProductBySlugQuery } from '../graphql/queries/ProductBySlug.generated';
import useGiftingContent from '../shared/useGiftingContent';

export type Option = {
  value: string;
  option: string;
  price?: number;
  name: string;
  skus: string[];
  productSlug: string;
  configuration: Selection[];
  months: string | undefined;
};

type ShopVariant = Pick<
  ProductVariant,
  'name' | 'skus' | 'id' | 'price' | 'configurations'
>;

const toMonthsConfiguration = (variant: ShopVariant) => {
  return variant.configurations.find(
    configuration => configuration.attribute === 'months',
  );
};

const toPrepaidMembershipOptions = (
  productSlug: string,
  variants: ShopVariant[],
  content: string,
) => {
  return variants
    .filter(toMonthsConfiguration)
    .sort((a, b) => a.price.amount - b.price.amount)
    .map(variant => {
      return {
        value: variant.id.toString(),
        option: toFormattedText(content, {
          months: toMonthsConfiguration(variant)?.option,
          price: toDollars(variant.price.amount),
        }).toString(),
        price: variant.price.amount,
        name: variant.name,
        skus: variant.skus,
        configuration: variant.configurations,
        productSlug,
        months: toMonthsConfiguration(variant)?.option,
      };
    });
};

const useGetPrepaidMembershipOptions = (): Option[] => {
  const optionLabel = useGiftingContent('addAllAccessMembershipOption');
  const queryOptions = useCatalogQueryOptions({
    variables: { productSlug: 'pl-sub-monthly-prepaid' },
    throwError: false,
  });
  const { data } = useProductBySlugQuery({
    ...queryOptions,
    suspend: false,
  });

  return toPrepaidMembershipOptions(
    data?.catalog?.productBySlug?.slug ?? '',
    data?.catalog?.productBySlug?.variants ?? [],
    optionLabel,
  );
};

export default useGetPrepaidMembershipOptions;
