import useCartWithVariables from './useCartWithVariables';

const useGetTrackingCategories = () => {
  const cart = useCartWithVariables().data?.cart;
  if (!cart) return undefined;
  const categories = [
    (cart.hasBike || cart.hasRefurbBike) && 'bike',
    (cart.hasBikePlus || cart.hasRefurbBikePlus) && 'bike-plus',
    cart.hasGuide && 'guide',
    cart.hasTread && 'tread',
    cart.hasTreadPlus && 'tread-plus',
    cart.hasRow && 'row',
    cart.hasAccessories && 'accessory',
    cart.hasPrepaidCredit && 'Peloton All Access Membership',
  ];
  return categories.filter(Boolean).join(',');
};
export default useGetTrackingCategories;
