import { Flex, Label } from '@pelotoncycle/design-system';
import React from 'react';
import useCartWithVariables from '../hooks/useCartWithVariables';
import CartContent from '../shared/CartContent';
import CartMarkdownContent from '../shared/CartMarkdownContent';
import CartTooltip from '../shared/CartTooltip';
import infoMarkdownProps from '../shared/InfoMarkdownProps';
import PriceWithCents from '../shared/PriceWithCents';

const Tax: React.FC<React.PropsWithChildren<unknown>> = () => {
  const tax = useCartWithVariables().data?.cart?.tax;

  return tax?.isVat ? null : (
    <Flex justifyContent="space-between">
      <Flex>
        <Label size="large" as="span">
          <CartContent
            field={!tax || tax.isEstimated ? 'estimatedTaxLabel' : 'taxLabel'}
          />
        </Label>
        {tax?.isEstimated && (
          <CartTooltip dataTestId="estimated-tax-tooltip">
            <CartMarkdownContent
              field="estimatedTaxMarkdown"
              renderers={infoMarkdownProps}
            />
          </CartTooltip>
        )}
      </Flex>
      <Label size="large" as="span">
        {tax && tax.price.amount !== 0 ? (
          <PriceWithCents amount={tax.price.amount} />
        ) : (
          <CartContent field="noAmount" />
        )}
      </Label>
    </Flex>
  );
};

export default Tax;
