import React, { useState } from 'react';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import { useRemoveCouponMutation } from '../../graphql/mutations/RemoveCoupon.generated';
import { useCartQuery } from '../../graphql/queries/Cart.generated';
import DiscountView from './DiscountView';

const Discounts: React.FC<React.PropsWithChildren<unknown>> = () => {
  const isProjectPhoenixEnabled = useIsToggleActive()('projectPhoenix');
  const { data } = useCartQuery({
    variables: { calculateEstimatedShippingPrice: isProjectPhoenixEnabled },
    suspend: false,
  });

  const [removeCouponFromCart, { loading }] = useRemoveCouponMutation();
  const [couponRemoved, setCouponRemoved] = useState('');

  const removeCoupon = async (code: string) => {
    setCouponRemoved(code);
    await removeCouponFromCart({
      variables: {
        cartId: data?.cart?.id ?? '',
        couponCode: code,
        calculateEstimatedShippingPrice: isProjectPhoenixEnabled,
      },
    });
    setCouponRemoved('');
  };

  return (
    <>
      {data?.cart?.discounts.map(({ code, value }) => (
        <DiscountView
          key={code}
          code={code}
          amount={value.amount}
          isLoading={loading && code === couponRemoved}
          removeButtonDisabled={loading}
          onRemove={() => removeCoupon(code)}
        />
      ))}
    </>
  );
};

export default Discounts;
