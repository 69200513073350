import { Container, Eyebrow, Flex, spacing } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import CartContent from '@ecomm/cart-next/shared/CartContent';
import CheckoutButton from '../shared/CheckoutButton';
import CartItems from './cart-items/CartItems';
import AddPromoCode from './discounts/AddPromoCode';
import Discounts from './discounts/Discounts';
import Financing from './financing/Financing';
import Gifting from './Gifting';
import LeasingDisclaimer from './LeasingDisclaimer';
import MarginDivider from './MarginDivider';
import Otd from './Otd';
import ScreenReaderNewItemAlert from './ScreenReaderNewItemAlert';
import Shipping from './Shipping';
import Subtotal from './Subtotal';
import Tax from './Tax';
import Total from './Total';
import TradeIn from './TradeIn';
import Upsell from './Upsell';
import Vat from './Vat';

type Props = {
  isCheckout?: boolean;
  className?: string;
};

const CartSummaryView: React.FC<React.PropsWithChildren<Props>> = ({
  isCheckout,
  className,
}) => {
  return (
    <Flex flexDirection="column" style={{ minHeight: '100vh' }}>
      <Container data-test-id="orderSummary" className={className} padding={spacing[32]}>
        <Eyebrow size="large" marginBottom={spacing[16]}>
          <CartContent field={isCheckout ? 'checkoutTitle' : 'title'} />
        </Eyebrow>
        <ScreenReaderNewItemAlert />
        <CartItems areItemsRemovable={!isCheckout} />
        <MarginDivider />
        <Subtotal />
        <Shipping />
        <Discounts />
        <Tax />
        <AddPromoCode />
        <MarginDivider />
        <Total />
        {!isCheckout && <StyledLeasingDisclaimer />}
        <Vat />
        <StyledTradeIn />
        <Financing verticalMargin={`${spacing[16]} 0`} />
        {!isCheckout && <Otd />}
        {!isCheckout && <Gifting />}
        <Upsell />
      </Container>
      {!isCheckout && <CheckoutButton />}
    </Flex>
  );
};

const StyledTradeIn = styled(TradeIn)`
  margin-top: ${spacing[16]};
`;

const StyledLeasingDisclaimer = styled(LeasingDisclaimer)`
  margin-top: ${spacing[8]};
`;

export default CartSummaryView;
