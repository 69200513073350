import { isPrepaidCreditItem } from '../cart-summary/cart-items/models';
import useCartWithVariables from './useCartWithVariables';

const useGetNumberOfPrepaidCreditsInCart = () => {
  const cart = useCartWithVariables().data?.cart;

  if (!cart || !cart.hasPrepaidCredit) return 0;

  return (cart?.items ?? []).find(isPrepaidCreditItem)?.numberOfCredits ?? 0;
};

export default useGetNumberOfPrepaidCreditsInCart;
