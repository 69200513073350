import { useEffect, useState } from 'react';
import { useTracking } from 'react-tracking';
import useGetCartItems from '@ecomm/commercetools/hooks/useGetCartItems';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import { useConvertToMonolithCartForReferral } from '@ecomm/pg-checkout-commercetools/utils/cartUtils';
import { useMigrationStatus } from '@ecomm/pg-checkout-commercetools/utils/useMigrationStatus';
import {
  useSetIsCartLoading,
  useSetCouponCodeError,
  useHasCouponAutoApplied,
  useSetHasCouponAutoApplied,
} from '../context/CartContext';
import type { AddPromoCodeMutationResult } from '../graphql/mutations/AddPromoCode.generated';
import { useAddPromoCodeMutation } from '../graphql/mutations/AddPromoCode.generated';
import type { CartQuery } from '../graphql/queries/Cart.generated';
import type { StorageItem } from '../shared/getStorageItem';
import { getStorageItem } from '../shared/getStorageItem';
import { hasReferralDiscount } from './referralUtils';
import {
  PARTNER_STORAGE_KEY,
  REFERRAL_CODE_STORAGE_KEY,
  usePartnerPromo,
} from './usePartnerPromo';

export enum Events {
  PageView = 'Viewed Shop Page with Referral Code',
  PageViewError = 'Error Storing Referral Code',
  AutoApply = 'Referral Code Auto Applied',
  AutoApplyError = 'Error Auto Applying Referral Code',
}

export const REFERRAL_CODE_PARAM = 'referralCode';
export const REFERRER_USER_PARAM = 'referringMember';
export const PARTNER_KEY = 'ecomm/referralPartner';

export const useReferralCodeFromUrl = () => {
  const [referral, setReferral] = useState<StorageItem | null>();
  const { trackEvent } = useTracking();
  const hasCouponAutoApplied = useHasCouponAutoApplied();
  const { handleCartConversion } = useConvertToMonolithCartForReferral();
  const { buyFlowCheckoutEnabled } = useMigrationStatus();

  useEffect(() => {
    if (hasCouponAutoApplied) {
      return;
    }
    const params = new URLSearchParams(window.location.search);
    const referralCode = params.get(REFERRAL_CODE_PARAM);
    const referrerUserId = params.get(REFERRER_USER_PARAM);

    const setReferralInLocal = async () => {
      if (typeof referralCode === 'string') {
        const storageItem: StorageItem = {
          referralCode,
          referrerUserId,
        };
        try {
          // only converting cart if CT is disabled
          if (!buyFlowCheckoutEnabled) {
            await handleCartConversion();
          }
          localStorage.setItem(REFERRAL_CODE_STORAGE_KEY, JSON.stringify(storageItem));
          setReferral(storageItem);
          trackEvent({
            event: Events.PageView,
            properties: {
              referralCode,
              referrerUserId,
            },
          });
        } catch {
          trackEvent({
            event: Events.PageViewError,
            properties: {
              referralCode,
              referrerUserId,
            },
          });
        }
      }
    };
    setReferralInLocal();

    setReferral(getStorageItem(REFERRAL_CODE_STORAGE_KEY) as StorageItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { hasCouponAutoApplied, referralCode: referral?.referralCode };
};

export const useReferralCodeFromStorage = (cartData: CartQuery | undefined) => {
  const isToggleActive = useIsToggleActive();
  const isProjectPhoenixEnabled = isToggleActive('projectPhoenix');
  const isFebruaryAamReferralPulseEnabled = isToggleActive('februaryAamReferralPulse');
  const [addCouponToCart, { loading }] = useAddPromoCodeMutation();
  const [referral, setReferral] = useState<StorageItem | null>();
  const setIsCartLoading = useSetIsCartLoading();
  const setCouponCodeError = useSetCouponCodeError();
  const setHasCouponAutoApplied = useSetHasCouponAutoApplied();
  const { trackEvent } = useTracking();
  const partnerPromo = usePartnerPromo();
  const currentReferralCode = getStorageItem(REFERRAL_CODE_STORAGE_KEY) as StorageItem;
  const { data: shopCart } = useGetCartItems();

  useEffect(() => {
    if (currentReferralCode?.referralCode !== referral?.referralCode) {
      setReferral(currentReferralCode as StorageItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReferralCode, referral?.referralCode]);

  useEffect(() => {
    if (loading) {
      setIsCartLoading(true);
    }
  }, [loading, setIsCartLoading]);

  useEffect(() => {
    if (shopCart?.discounts && hasReferralDiscount(shopCart.discounts) && referral) {
      // remove referral code in localStorage after an auto-applied referral discount is added to a CT cart
      localStorage.removeItem(REFERRAL_CODE_STORAGE_KEY);
      setReferral(null);
      setHasCouponAutoApplied(true);
      setIsCartLoading(false);
    }
  }, [shopCart, referral]);

  useEffect(() => {
    if (!referral || loading || !cartData?.cart) {
      return;
    }
    const configureTrackingProperties = (
      baseProperties: StorageItem,
      partner?: string,
    ) => {
      const activePartner = Boolean(partner);
      if (activePartner) {
        localStorage.setItem(PARTNER_KEY, partner as string);
        return { ...baseProperties, partner };
      } else {
        return baseProperties;
      }
    };
    const { referralCode, referrerUserId } = referral;
    const handleUpdate = (response: AddPromoCodeMutationResult) => {
      const mutationData = response?.data;
      if (mutationData?.addCouponToCart) {
        if (mutationData.addCouponToCart.__typename === 'DomainError') {
          const errorCode = mutationData.addCouponToCart.code;
          setCouponCodeError({
            errorCode,
            couponCode: referralCode,
          });
          trackEvent({
            event: Events.AutoApplyError,
            properties: {
              referralCode,
              referrerUserId,
              errorCode,
            },
          });
        } else {
          const baseProperties = { referralCode, referrerUserId };
          const properties = configureTrackingProperties(
            baseProperties,
            partnerPromo?.partner,
          );
          trackEvent({
            event: Events.AutoApply,
            properties,
          });
        }
        localStorage.removeItem(REFERRAL_CODE_STORAGE_KEY);
        localStorage.removeItem(PARTNER_STORAGE_KEY);
        setReferral(null);
        setHasCouponAutoApplied(true);
        setIsCartLoading(false);
        return;
      }
    };
    const cart = cartData.cart;
    const promoItemsInCart = partnerPromo.verifyProductSelection(cart);
    if (Boolean(partnerPromo?.partnerParamsPresent)) {
      if (Boolean(promoItemsInCart)) {
        const partner = partnerPromo.partner;
        addCouponToCart({
          variables: {
            couponCode: referralCode,
            cartId: cart?.id as string,
            calculateEstimatedShippingPrice: isProjectPhoenixEnabled,
            partner,
          },
        }).then(handleUpdate);
      }
    } else {
      const referralInStorage = getStorageItem(REFERRAL_CODE_STORAGE_KEY);
      if (
        Boolean(
          cart?.hasEquipment &&
            referralInStorage &&
            (cart?.hasAccessories || isFebruaryAamReferralPulseEnabled),
        )
      ) {
        addCouponToCart({
          variables: {
            couponCode: referralCode,
            cartId: cart.id,
            calculateEstimatedShippingPrice: isProjectPhoenixEnabled,
          },
        }).then(handleUpdate);
      }
    }
  }, [
    addCouponToCart,
    cartData,
    isProjectPhoenixEnabled,
    loading,
    partnerPromo,
    referral,
    setCouponCodeError,
    setHasCouponAutoApplied,
    setIsCartLoading,
    trackEvent,
    isFebruaryAamReferralPulseEnabled,
  ]);
};
