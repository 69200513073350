import { Label } from '@pelotoncycle/design-system';
import React from 'react';
import MembershipItemTitleView from './MembershipItemTitleView';

type Props = {
  name: string;
  isMembership: boolean;
};

const ItemTitleView: React.FC<React.PropsWithChildren<Props>> = ({
  name,
  isMembership,
}) => {
  return isMembership ? (
    <MembershipItemTitleView name={name} />
  ) : (
    <Label data-test-id="item-name" size="large">
      {name}
    </Label>
  );
};

export default ItemTitleView;
