import { Container, Flex, grey, Label, Support } from '@pelotoncycle/design-system';
import React from 'react';
import useCartWithVariables from '../../hooks/useCartWithVariables';
import CartContent from '../../shared/CartContent';
import CartMarkdownContent from '../../shared/CartMarkdownContent';
import CartTooltip from '../../shared/CartTooltip';
import infoMarkdownProps from '../../shared/InfoMarkdownProps';

const GuideMembershipView: React.FC<React.PropsWithChildren<unknown>> = () => {
  const cart = useCartWithVariables().data?.cart;

  return cart && cart.hasGuide && !cart.hasMembership ? (
    <Container maxWidth="256px">
      <Flex>
        <Label data-test-id="item-name" size="large">
          <CartContent field="guideMembershipName" />
        </Label>
        <CartTooltip dataTestId="tooltip">
          <CartMarkdownContent
            field="guideMembershipInfo"
            renderers={infoMarkdownProps}
          />
        </CartTooltip>
      </Flex>
      <Support size="small" textColor={grey[70]}>
        <CartContent field="guideMembershipDescription" />
      </Support>
    </Container>
  ) : null;
};

export default GuideMembershipView;
