//eslint-disable-next-line
import { spacing, grey } from '@pelotoncycle/design-system';
import React from 'react';
import type { CSSProperties } from 'styled-components';
import styled from 'styled-components';
import { LinkButton } from '@peloton/internationalize-ui/components/LinkButton';
import useIsToggleActive from '../../feature-toggle/hooks/useIsToggleActive';
import useCartContent from './useCartContent';

type Props = {
  style?: CSSProperties;
};

const CheckoutButton: React.FC<React.PropsWithChildren<Props>> = ({ style }) => {
  const isGiftingEnhancementEnabled = useIsToggleActive()('giftingCheckoutWithoutAam');

  return (
    <StyledDiv isSticky={isGiftingEnhancementEnabled}>
      <LinkButton
        style={style}
        icon="lockInteraction"
        width="adaptive"
        data-test-id="checkoutLink"
        href="/checkout"
        text={useCartContent('checkout')}
      />
    </StyledDiv>
  );
};

const StyledDiv = styled.div<{ isSticky: boolean }>`
  ${props =>
    props.isSticky
      ? `
      background-color: ${grey[30]}; 
      position: sticky; 
      bottom: 0; 
      height: 100%; 
      flex-grow: 1;
      padding: ${spacing[16]} ${spacing[32]}; 
      filter: drop-shadow(0 20px 30px rgba(0, 0, 0, 0.25));
      `
      : `padding: 0 ${spacing[32]} ${spacing[32]} ${spacing[32]}`}
`;

export default CheckoutButton;
