import { Label, spacing, Container } from '@pelotoncycle/design-system';
import React from 'react';
import CartMarkdownContent from '../../shared/CartMarkdownContent';
import CartTooltip from '../../shared/CartTooltip';
import infoMarkdownProps from '../../shared/InfoMarkdownProps';

type Props = {
  name: string;
};

const MembershipItemTitleView: React.FC<React.PropsWithChildren<Props>> = ({ name }) => {
  return (
    <Container maxWidth="240px">
      <Label display="inline" data-test-id="item-name" size="large">
        {name}
      </Label>
      <Container
        verticalMargin={`${spacing[4]} 0`}
        display="inline-block"
        style={{ verticalAlign: 'middle' }}
        width="fit-content"
      >
        <CartTooltip dataTestId="tooltip">
          <CartMarkdownContent field="membershipInfo" renderers={infoMarkdownProps} />
        </CartTooltip>
      </Container>
    </Container>
  );
};

export default MembershipItemTitleView;
