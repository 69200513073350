import { Locale } from '@peloton/internationalize/models/locale';
import type { Currency } from '@ecomm/graphql/types.generated';
import type { VariantCommerceTools } from '@ecomm/pg-shop-accessories-display/models';
import {
  AccessoryKeys,
  CONFIGURABLE_ATTRIBUTES,
  SUBSCRIPTION_CONFIGURABLE_ATTRIBUTES,
} from '../constants';
import type {
  ProductSearchVariant,
  ProductVariant,
  RawProductAttribute,
  RawProductSearchAttribute,
} from '../types.generated';

const getValueString = (value: any, locale: string) =>
  String(value.key || value[locale] || value[Locale.Default] || value);

const getVariantAttributeValue = (
  attributes: Array<RawProductAttribute | RawProductSearchAttribute>,
  name: string,
  locale: string,
): string => {
  const value = attributes.find(a => a.name === name)?.value || '';
  return getValueString(value, locale);
};

export const mapProductAttributesToConfigs = (
  masterVariant: ProductVariant | ProductSearchVariant,
  variants: ProductVariant[] | ProductSearchVariant[],
  locale: string,
  isSubscriptionProduct: boolean = false,
): VariantCommerceTools[] => {
  return variants.map(variant => {
    const { centAmount, currencyCode } =
      variant.price?.value || masterVariant.price?.value || {};

    const discountPriceBase = variant.price?.discounted?.value;

    const legacyOptionId = getVariantAttributeValue(
      variant.attributesRaw,
      AccessoryKeys.LEGACY_OPTION_ID,
      locale,
    );

    const variantDescription = getVariantAttributeValue(
      variant.attributesRaw,
      AccessoryKeys.VARIANT_DESCRIPTION,
      locale,
    );

    const arrayToCheck = isSubscriptionProduct
      ? SUBSCRIPTION_CONFIGURABLE_ATTRIBUTES
      : CONFIGURABLE_ATTRIBUTES;

    const unFilteredConfigurations = (variant.attributesRaw || []).map(attribute => ({
      attribute: attribute.name,
      option: getValueString(attribute.value, locale),
      legacyOptionId,
    }));

    const configurations = unFilteredConfigurations.filter(config => {
      return arrayToCheck.includes(config.attribute);
    });

    const price = {
      amount: Number(centAmount),
      currency: String(currencyCode) as Currency,
    };

    const variantDiscountedPrice = discountPriceBase && {
      amount: discountPriceBase.centAmount,
      currency: currencyCode,
    };

    return {
      key: String(variant.key),
      sku: String(variant.sku),
      discountPrice: variantDiscountedPrice,
      price,
      legacyOptionId,
      description: variantDescription,
      configurations,
    };
  });
};
