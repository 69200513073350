import { Flex, Label, black } from '@pelotoncycle/design-system';
import React from 'react';
import useCartContent from '@content/client/www/cart/useCart';
import FormatText from '@ecomm/copy/FormatText';
import { toDollars } from '@ecomm/models';

type Props = {
  subtotal: number;
  numberOfItems: number;
};

const Subtotal: React.FC<React.PropsWithChildren<Props>> = ({
  subtotal,
  numberOfItems,
}) => {
  const { content: cartContent } = useCartContent();
  return (
    <Flex justifyContent="space-between" data-test-id="subtotal-container">
      <Label size="large" data-test-id="subtotal-label" textColor={black}>
        <FormatText
          content={cartContent?.subtotalLabel?.value}
          values={{ quantity: numberOfItems }}
        />
      </Label>
      <Label size="large" as="span" textColor={black}>
        <FormatText
          content="{price, number, currencyWithCents}"
          values={{ price: toDollars(subtotal) }}
        />
      </Label>
    </Flex>
  );
};

export default Subtotal;
