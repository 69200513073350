import {
  grey,
  black,
  spacing,
  Flex,
  Label,
  Support,
  yellow,
  Media,
} from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import useCartContent from '@content/client/www/cart/useCart';
import FormatText from '@ecomm/copy/FormatText';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import type {
  LineItemDataFragment,
  ShopLineItemDataFragment,
} from '@ecomm/shop-cart/graphql/fragments.generated';
import {
  isProductAvailable,
  isUnavailableProductExists,
  isCFUbundle,
} from '../../helpers/ct-cart-helper';
import { CartItemPrice } from './cartPanelPrice';
import type { RemoveItemArgs } from './CartSummary';
import { IncrementDecrement } from './IncrementDecrement';

type RemovableCartItemProps = {
  className?: string;
  removeItem: ({ id, isBundle, isBundleCFU }: RemoveItemArgs) => void;
  cartItem: LineItemDataFragment;
};

type BundleLineItemProps = {
  bundleItemLineItems: ShopLineItemDataFragment[];
};

const BundleLineItems = ({ bundleItemLineItems }: BundleLineItemProps) => (
  <>
    {bundleItemLineItems.map((bundleItem: LineItemDataFragment, index: number) => {
      const bundleItemQty = bundleItem.quantity;
      const bundleItemName = bundleItem.productVariant?.name ?? '';
      const bundleItemDisplayName = Boolean(bundleItemQty > 1)
        ? `${bundleItemName} (${bundleItemQty})`
        : bundleItemName;

      return (
        <StyledBundleItem key={`bundle-item-${index}`} data-test-id="bundle-item">
          {bundleItemDisplayName}
        </StyledBundleItem>
      );
    })}
  </>
);

const getItemValues = (item: LineItemDataFragment) => {
  const {
    productVariant,
    priceSummary,
    bundleItemLineItems,
    configurationStatus = '',
    quantity = 0,
    id = '',
  } = item;

  return {
    id,
    name: productVariant?.name ?? '',
    quantity,
    bundleItemLineItems: bundleItemLineItems || [],
    isBundle: Boolean(bundleItemLineItems?.length),
    configurationStatus: configurationStatus || '',
    subtotal: priceSummary?.subtotal?.centAmount ?? 0,
    discountTotal: priceSummary?.discountTotal?.centAmount ?? 0,
    productImage: productVariant?.images?.[0],
  };
};

const RemovableCartItemView: React.FC<
  React.PropsWithChildren<RemovableCartItemProps>
> = ({ removeItem, cartItem }) => {
  const { content: cartContent } = useCartContent();
  const isApparelCartUIActive = useIsToggleActive()('apparelCartUI');

  const {
    id,
    name,
    quantity: itemQty,
    subtotal: itemPrice,
    discountTotal: itemDiscountedPrice,
    configurationStatus,
    bundleItemLineItems,
    productImage,
    isBundle,
  } = getItemValues(cartItem);

  const lineItemBasePrice = isApparelCartUIActive ? itemDiscountedPrice : itemPrice;
  const hasDiscount = itemDiscountedPrice > 0;
  const isDiscountedPrice = hasDiscount && isApparelCartUIActive;
  const isProductUnavailable =
    !isProductAvailable(configurationStatus) ||
    isUnavailableProductExists(bundleItemLineItems || []);

  const isBundleCFU = isBundle && isCFUbundle(cartItem);

  return (
    <Item>
      <Flex gap={spacing[16]}>
        {isApparelCartUIActive && (
          <Flex>
            {productImage ? (
              <Flex data-test-id="hero-image">
                <Media
                  height={spacing[88]}
                  width={spacing[72]}
                  media={{
                    desktop: productImage?.url ?? '',
                    mobile: productImage?.url ?? '',
                    alt: productImage?.label ?? '',
                    type: 'image',
                  }}
                  objectFit="scale-down"
                />
              </Flex>
            ) : (
              <EmptyImagePlaceholder data-test-id="no-hero-image" />
            )}
          </Flex>
        )}
        <StyledDescriptionLabel>
          <Flex justifyContent="space-between" alignItems="flex-start">
            <Flex flexDirection={'column'}>
              <Label data-test-id="item-name" size="large" textColor={grey[90]}>
                {name}
              </Label>
              {isApparelCartUIActive && Boolean(bundleItemLineItems.length) && (
                <StyledBundleWrapper data-test-id="bundle-item-line-items">
                  <BundleLineItems bundleItemLineItems={bundleItemLineItems} />
                </StyledBundleWrapper>
              )}
            </Flex>
            {!isApparelCartUIActive ? (
              <Support
                data-test-id="qty"
                size="medium"
                display="block"
                style={{ whiteSpace: 'nowrap' }}
              >
                <FormatText
                  content={cartContent?.quantity?.value}
                  values={{ quantity: itemQty }}
                />
              </Support>
            ) : (
              <CartItemPrice
                isDiscountedPrice={isDiscountedPrice}
                lineItemBasePrice={lineItemBasePrice}
                itemTotalPrice={itemPrice}
              />
            )}
          </Flex>
          <Flex justifyContent="space-between" alignItems="flex-start">
            {isApparelCartUIActive ? (
              <IncrementDecrement cartItem={cartItem} removeItem={removeItem} />
            ) : (
              <Flex flexDirection="column">
                {Boolean(bundleItemLineItems.length) && (
                  <StyledBundleWrapper data-test-id="bundle-item-line-items">
                    <BundleLineItems bundleItemLineItems={bundleItemLineItems} />
                  </StyledBundleWrapper>
                )}
                <CartItemPrice
                  isDiscountedPrice={isDiscountedPrice}
                  lineItemBasePrice={lineItemBasePrice}
                  itemTotalPrice={itemPrice}
                />
              </Flex>
            )}
            <button
              data-test-id="remove-button"
              onClick={() =>
                removeItem({
                  id,
                  isBundle,
                  isBundleCFU,
                  quantity: isApparelCartUIActive ? itemQty : 1,
                })
              }
            >
              <StyledLabel size="medium" textColor={grey[90]} is="span">
                {cartContent?.remove?.value}
              </StyledLabel>
            </button>
          </Flex>
        </StyledDescriptionLabel>
      </Flex>
      {isProductUnavailable && (
        <ProductUnavailable>
          <StyledProductUnavailableLabel size="medium" textColor={black}>
            {cartContent?.unavailableProductTxt?.value}
          </StyledProductUnavailableLabel>
        </ProductUnavailable>
      )}
    </Item>
  );
};

const Item = styled.div`
  border-top: 1px solid ${grey[40]};
  padding: ${spacing[24]} 0;

  &:first-of-type {
    border-top: none;
    padding: ${spacing[16]} 0 ${spacing[24]};
    ${media.tabletXLarge`
        padding: ${spacing[24]} 0;
      `}
  }
`;

const StyledDescriptionLabel = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
`;

const EmptyImagePlaceholder = styled.div`
  height: ${spacing[88]};
  width: ${spacing[72]};
  background: ${grey[30]};
`;

const StyledLabel = styled(Label)`
  text-decoration: underline;
`;

const StyledBundleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${spacing[4]};
`;

const StyledBundleItem = styled.h6`
  font-family: Inter, sans-serif;
  font-weight: 300;
  letter-spacing: 0.3px;
  display: inline-block;
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: #65666a;
`;

const ProductUnavailable = styled.div`
  background-color: ${yellow[50]};
  text-align: center;
  margin-top: ${spacing[16]};
`;

const StyledProductUnavailableLabel = styled(Label)`
  padding: ${spacing[8]} 0;
`;

export default RemovableCartItemView;
