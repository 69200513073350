import { grey, Label } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import type { PropsOf } from '@peloton/react';
import CartContent from './CartContent';

type Props = {
  size?: PropsOf<typeof Label>['size'];
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
};

const noop = () => undefined;

const RemoveButton: React.FC<React.PropsWithChildren<Props>> = ({
  size,
  onClick = noop,
  className,
  disabled = false,
}) => (
  <button onClick={onClick} disabled={disabled} data-test-id="remove-button">
    <StyledLabel className={className} size={size} textColor={grey[70]} is="span">
      <CartContent field="remove" />
    </StyledLabel>
  </button>
);

const StyledLabel = styled(Label)`
  text-decoration: underline;
`;

export default RemoveButton;
