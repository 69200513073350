import React from 'react';
import DynamicOtdEstimate from '@ecomm/cart/summary/OtdEstimate/DynamicOtdEstimate';
import useGetCartItems from '@ecomm/commercetools/hooks/useGetCartItems';
import { useOtdEstimate } from '@ecomm/otd/useOtdEstimates';
import { useGetCfuTypesInCTCart } from '../../hooks/useGetCfuTypesInCTCart';

const Otd = () => {
  const { shopCartResult: shopCartData } = useGetCartItems();
  const savedPostalCode = shopCartData?.shopCart?.shippingAddress?.postalCode ?? '';
  const {
    allCfuTypes: [initialCfu],
  } = useGetCfuTypesInCTCart();
  const countryEstimate = useOtdEstimate(initialCfu);

  return (
    <DynamicOtdEstimate
      cfuType={initialCfu}
      postalCode={savedPostalCode}
      countryEstimate={countryEstimate}
    />
  );
};

export default Otd;
