import Head from 'next/head';
import React from 'react';
import { TLD } from '@peloton/app-config';
import { toExtLinkEnv, toWWWLink } from '@peloton/external-links/models';
import { AVAILABLE_LOCALES_FOR_ECOMM_INTERNATIONALIZE } from '@peloton/internationalize/consolidatedAppConfigs/models';
import { Locale } from '@peloton/internationalize/models/locale';
import toLinks from '@ecomm/metadata/toLinks';
import { ensureHttpsAddress } from '@page-builder/utils/helpers/regex';

export type MetaDataProps = {
  title: string;
  description: string;
  keywords: string[];
  noIndex?: boolean;
  ogtype?: string;
  imageUrl?: string | null;
  locale?: Locale;
  slug?: string;
  id?: string;
  availableHrefLangs?: Partial<Locale>[];
};

const isHomepageSlug = (slug: string = '') => decodeURIComponent(slug) === '/';
const DEFAULT_IMAGE_URL = '/images/metadataBike.jpg';

export const NextMetadataTags: React.FC<React.PropsWithChildren<MetaDataProps>> = ({
  title,
  description,
  keywords,
  noIndex,
  ogtype,
  imageUrl,
  locale,
  slug,
  availableHrefLangs = AVAILABLE_LOCALES_FOR_ECOMM_INTERNATIONALIZE,
}) => {
  const rootUrl = `https://www.onepeloton${TLD}`;
  const canonicalSlug = slug?.startsWith('/') ? slug : `/${slug}`;
  const canonicalLink = toWWWLink(canonicalSlug || '');
  const localePath = locale === Locale.EnglishUnitedStates ? '' : `/${locale}`;
  const ogUrl =
    !isHomepageSlug(slug) && slug
      ? `https://www.onepeloton${TLD}${localePath}${canonicalLink.path}`
      : `https://www.onepeloton${TLD}${localePath}`;

  return (
    <Head>
      {/* viewport and title tags not allowed in _document.tsx */}
      <title>{title}</title>
      <meta key="description" name="description" content={description} />
      <meta key="keywords" name="keywords" content={keywords?.join(',')} />
      <meta key="locale" property="og:locale" content={locale} />
      {toLinks({ extLinkEnv: toExtLinkEnv(), canonicalLink, availableHrefLangs, locale })}
      <meta key="og-type" property="og:type" content={ogtype || `website`} />
      <meta key="og-title" property="og:title" content={title || ''} />
      <meta key="og-description" property="og:description" content={description} />
      <meta key="url" property="og:url" content={ogUrl} />
      <meta
        key="og-image"
        property="og:image"
        content={
          (imageUrl && ensureHttpsAddress(imageUrl)) ?? rootUrl + DEFAULT_IMAGE_URL
        }
      />
      {noIndex && <meta data-rule="page-only" name="robots" content="noindex" />}
    </Head>
  );
};

export default NextMetadataTags;
