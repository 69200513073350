import React from 'react';
import useCartWithVariables from '../hooks/useCartWithVariables';
import CartMarkdownContent from '../shared/CartMarkdownContent';
import CartTooltip from '../shared/CartTooltip';
import infoMarkdownProps from '../shared/InfoMarkdownProps';

type Props = {
  thirdParty?: boolean;
  deliveryDate?: string;
};

const ShippingTooltip: React.FC<React.PropsWithChildren<Props>> = ({
  thirdParty = true,
  deliveryDate = 'unscheduled',
}) => {
  const cart = useCartWithVariables().data?.cart;

  if (cart) {
    const field = getCopyField(cart);
    return (
      <CartTooltip dataTestId="shipping-info-tooltip">
        <CartMarkdownContent
          field={field}
          renderers={infoMarkdownProps}
          values={{ thirdParty: String(thirdParty), deliveryDate }}
        />
      </CartTooltip>
    );
  }
  return null;
};

type CartHas = {
  hasTread: boolean;
  hasBike: boolean;
  hasBikePlus: boolean;
  hasGuide: boolean;
  hasRow: boolean;
  hasEquipment: boolean;
};

const getCopyField = (cart: CartHas) => {
  const { hasTread, hasBike, hasBikePlus, hasGuide, hasRow } = cart;
  switch (true) {
    case hasTread && (hasBike || hasBikePlus):
      return 'shippingMethodDetailsBikeAndTread';
    case hasBike || hasBikePlus:
      return 'shippingMethodDetailsBike';
    case hasTread:
      return 'shippingMethodDetailsTread';
    case hasGuide:
      return 'shippingMethodDetailsGuide';
    case hasRow:
      return 'shippingMethodDetailsRow';
    default:
      return 'shippingMethodDetailsAccessories';
  }
};

export default ShippingTooltip;
