import { Flex, Label } from '@pelotoncycle/design-system';
import React from 'react';
import useCartWithVariables from '../hooks/useCartWithVariables';
import CartContent from '../shared/CartContent';
import PriceWithCents from '../shared/PriceWithCents';

const Total: React.FC<React.PropsWithChildren<unknown>> = () => {
  const cart = useCartWithVariables().data?.cart;
  const shipping = cart?.shipping;
  const hasEquipmentLease = cart?.hasEquipmentLease;

  return (
    <Flex justifyContent="space-between">
      <Label size="large" as="span">
        <CartContent
          field={
            hasEquipmentLease
              ? 'leaseEstimatedTotal'
              : !shipping || shipping.isEstimated
              ? 'estimatedTotalLabel'
              : 'totalLabel'
          }
        />
      </Label>
      <Label size="extraLarge" as="span">
        <PriceWithCents amount={cart?.total.amount ?? 0} />
      </Label>
    </Flex>
  );
};

export default Total;
