import {
  Flex,
  Eyebrow,
  grey,
  Media,
  Label,
  SingleSelect,
  Button,
} from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import useCart from '@content/client/www/cart/useCart';
import type { Item } from '@ecomm/accessories/ConfigurationInputView';
import useCatalogQueryOptions from '@ecomm/graphql/useCatalogQueryOptions';
import { useUpsellQuery } from '../graphql/queries/Upsell.generated';
import CartContent from '../shared/CartContent';
import CartTooltip from '../shared/CartTooltip';
import infoMarkdownProps from '../shared/InfoMarkdownProps';

type Props = {
  upsellType: string[];
  cart?: any;
};

const UpsellItem: React.FC<React.PropsWithChildren<Props>> = ({ upsellType, cart }) => {
  // TODO: Might need to add a field for option prices/slug when we implement addToCart
  const content = useCart();
  const [selectedItem, setSelectedItem] = React.useState({
    option: '',
    value: '',
  });
  const opts = useCatalogQueryOptions({
    variables: { productTags: upsellType },
  });
  const upsellItems = useUpsellQuery({
    ...opts,
    suspend: false,
  }).data?.catalog?.products;

  if (upsellItems === undefined) {
    return null;
  }
  const upsellItem = upsellItems?.[upsellItems.length - 1];

  // TODO: Need to change price to match selected option
  // TODO: Need to add addToCart & Error Message
  // TODO: Remove Upsell if relevant item is in cart (Check if item is in cart using slug)
  return (
    <>
      <Eyebrow as="h6" textAlign="center" display="block" marginBottom="10px">
        <CartContent field="upsellTitle" />
      </Eyebrow>
      <Flex
        backgroundColor="white"
        padding="20px"
        border={`1px solid ${grey[40]}`}
        minWidth="100%"
        flexDirection="column"
        gap="15px"
      >
        <Flex alignItems="center">
          <Media
            media={{
              alt: upsellItem.name,
              mobile: upsellItem.image?.src,
              desktop: upsellItem.image?.src,
              type: 'image',
            }}
            height="50px"
            width="50px"
          ></Media>
          <Flex>
            <StyledLabel size="large">{upsellItem.name}</StyledLabel>
            <CartTooltip dataTestId="upsell-info-tooltip">
              <infoMarkdownProps.heading>{upsellItem.name}</infoMarkdownProps.heading>
              <infoMarkdownProps.paragraph>Get From Catalog </infoMarkdownProps.paragraph>
            </CartTooltip>
          </Flex>
        </Flex>
        <SingleSelect
          label="Select Item"
          handleSelectedItemChange={(chosenItem: Item) => {
            setSelectedItem(chosenItem);
          }}
          items={upsellItem?.attributes?.[0].options
            ?.filter(hasOptions)
            .map((item: any) => ({
              option: item.name,
              value: upsellItem.slug,
            }))}
          selectedItem={selectedItem}
        ></SingleSelect>
        <Button
          color="dark"
          size="small"
          text={validEntries(upsellItem, content)}
          variant="outline"
          width="adaptive"
        ></Button>
      </Flex>
    </>
  );
};

const validEntries = (upsellItem: any, content: any) => {
  if (upsellItem.price?.amount && content.content?.addToCart?.value) {
    return `$${upsellItem.price?.amount / 100} - ${content.content?.addToCart?.value}`;
  }
  return undefined;
};

const hasOptions = (option: any) => {
  return option?.name && option?.slug;
};

const StyledLabel = styled(Label)`
  margin-left: 10px;
`;

export default UpsellItem;
